import PropTypes from "prop-types";
import React from "react";
import { Menu, MenuButton, MenuItem, useMenuState } from "reakit";
import { KeyboardArrowDown } from "styled-icons/material";
import {
  SCaret,
  SDropdownBox,
  SDropdownButton,
  SDropdownItem,
  SWrapper,
  SCustomButton,
} from "./styles";

const DropdownButton = ({ size, children, ...props }) => {
  if (size === "main") {
    return <SDropdownButton height="form">{children}</SDropdownButton>;
  }
  if (size === "sm") {
    return (
      <SDropdownButton height="formSM" fontSize="md" {...props}>
        {children}
      </SDropdownButton>
    );
  }
  return null;
};

/**
 * This component uses [Reakit Menu](https://reakit.io/docs/menu/) to handle it behavior.
 *
 */

const Dropdown = ({
  button,
  label,
  options,
  useCaret,
  size,
  isDisabled,
  children,
}) => {
  // TODO: Investigate deep about baseID
  const menuState = useMenuState({ baseId: "genid", gutter: 4 });

  const items = options.map(({ key, content, onClick }) => (
    <MenuItem
      as={SDropdownItem}
      key={key}
      onClick={() => {
        menuState.hide();
        if (onClick) {
          onClick();
        }
      }}
      {...menuState}
    >
      {content}
    </MenuItem>
  ));

  const ComponentButton = children ? SCustomButton : DropdownButton;

  return (
    <SWrapper>
      <ComponentButton
        as={MenuButton}
        {...menuState}
        disabled={isDisabled}
        size={size}
      >
        {children || button || label}
        {useCaret && (
          <SCaret isOpen={menuState.visible}>
            <KeyboardArrowDown size={24} />
          </SCaret>
        )}
      </ComponentButton>
      <Menu as={SDropdownBox} {...menuState} aria-label={label}>
        {items}
      </Menu>
    </SWrapper>
  );
};

Dropdown.defaultProps = {
  useCaret: true,
  isDisabled: false,
  size: "main",
  button: undefined,
  children: undefined,
};

Dropdown.propTypes = {
  label: PropTypes.string.isRequired,

  /** An element that replaces the label text, you can customize your dropdown  */
  button: PropTypes.node,

  /** Options to display in the dropdown list */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      content: PropTypes.node.isRequired,
      onClick: PropTypes.func,
    })
  ).isRequired,

  /** Display the caret icon */
  useCaret: PropTypes.bool,
  isDisabled: PropTypes.bool,

  size: PropTypes.oneOf(["main", "sm"]),

  /** Use Children to create a custom DropDown trigger */
  children: PropTypes.node,
};

DropdownButton.propTypes = {
  size: PropTypes.oneOf(["main", "sm"]).isRequired,
  children: PropTypes.node.isRequired,
};

export default Dropdown;
