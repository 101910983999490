import styled from "@xstyled/styled-components";
import { InputGroup } from "#components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: xs;
  padding-right: xs;
`;

export const StyledInputGroup = styled(InputGroup)`
  width: 100%;
  & > div {
    width: 100%;
  }
`;
