import { path } from "ramda";
import React from "react";
import { useSelector } from "react-redux";
import {
  CartIcon,
  CouponIcon,
  ExitIcon,
  PersonGroupIcon,
  ViewIcon,
} from "#components";
import {
  LogoutLink,
  StyledIcon,
  StyledLink,
  StyledText,
  Wrapper,
} from "./styles";

const Sidebar = () => {
  const settings = useSelector(path(["cart", "settings", "item"]));
  const isCouponsActive = settings.coupons && settings.coupons.active;

  return (
    <Wrapper>
      <StyledLink to="/products">
        <StyledIcon as={ViewIcon} />
        <StyledText>Productos</StyledText>
      </StyledLink>
      <StyledLink to="/orders">
        <StyledIcon as={CartIcon} />
        <StyledText>Pedidos</StyledText>
      </StyledLink>
      {isCouponsActive && (
        <StyledLink to="/coupons">
          <StyledIcon as={CouponIcon} />
          <StyledText>Cupones</StyledText>
        </StyledLink>
      )}
      <StyledLink to="/customers">
        <StyledIcon as={PersonGroupIcon} />
        <StyledText>Clientes</StyledText>
      </StyledLink>
      <LogoutLink to="/logout">
        <ExitIcon height="40px" width="40px" />
      </LogoutLink>
    </Wrapper>
  );
};

Sidebar.displayName = "Sidebar";

export default Sidebar;
