import PropTypes from "prop-types";
import React from "react";
import globalProps from "../../../props";
import { oneOfThemeColor } from "../../../types";
import { useFormControl } from "../FormControl";
import { SButton, SIcon, SWrapper } from "./styles";

/**
 * This is the generic button component.
 *
 * We use like basis [Reakit Button](https://reakit.io/docs/button/)
 */
const Button = ({
  label,
  icon: Icon,
  iconSize,
  iconColor,
  variant,
  onClick,
  size,
  width,
  shape,
  className,
  ...props
}) => {
  const { isDisabled } = useFormControl(props);
  return (
    <SWrapper width={width} shape={shape} during={700}>
      <SButton
        width={width}
        variant={variant}
        disabled={isDisabled}
        onClick={isDisabled ? undefined : onClick}
        size={size}
        shape={shape}
        className={className}
      >
        {Icon && <SIcon as={Icon} iconColor={iconColor} iconSize={iconSize} />}
        {label}
      </SButton>
    </SWrapper>
  );
};

Button.defaultProps = {
  ...globalProps.styledComponents.className.defaultProps,
  label: "",
  icon: undefined,
  onClick: undefined,
  isDisabled: false,
  iconSize: "sm",
  iconColor: "currentColor",
};

Button.propTypes = {
  ...globalProps.styledComponents.className.types,
  /** Text to display on the button */
  label: PropTypes.string,

  /** Style variant that indicates the form status */
  variant: PropTypes.oneOf([
    "primary",
    "secondary",
    "ghost",
    "link",
    "ok",
    "ko",
  ]),

  /** Style width indicates the width of the Button, auto and 100% width */
  width: PropTypes.oneOf(["default", "block"]),

  /** Size variation default(default) and small(sm) */
  size: PropTypes.oneOf(["default", "sm", "lg", "xl"]),

  //* * Shape of The Button */
  shape: PropTypes.oneOf(["default", "circle", "square"]),

  /** Icon Variation admites any component,
   * default usage:styled-icons/material */
  icon: PropTypes.elementType,

  /** Icon Size
   * sm-16px, default-30px, form-40px, lg-50px, xl-64px
   */
  iconSize: PropTypes.oneOf(["sm", "default", "form", "lg", "xl"]),

  /** Icon color */
  iconColor: oneOfThemeColor([
    "currentColor",
    "text",
    "primary",
    "secondary",
    "buttonText",
    "bg",
    "fg",
    "warning",
    "ok",
    "ko",
  ]),

  /** Generic onclick handler */
  onClick: PropTypes.func,
  isDisabled: PropTypes.bool,
};

export default Button;
