const types = {
  info: "info",
  warning: "warning",
  success: "success",
  error: "error",
};

const colors = {
  [types.info]: "primary",
  [types.warning]: "warning",
  [types.success]: "ok",
  [types.error]: "ko",
};

export default Object.freeze({
  types,
  colors,
});
