import { ThemeProvider as Provider } from "@xstyled/styled-components";
import PropTypes from "prop-types";
import React, {
  Children,
  createContext,
  useContext,
  useMemo,
  useState,
} from "react";
import GlobalStyle from "./GlobalStyle";
import { processModes, processTheme } from "./utils";

const ThemeContext = createContext({});

/**
 *
 * ThemeProvider is an enhanced version of the xstyled provider, it allows us to
 * use style modes that allow us to toggle between styles depending on your
 * requirements, also we can use the useThemeContext hook to enabled or disabled
 * these style modes and for access to the information of the mode styles
 *
 * Automatically the ThemeProvider component enhances the color schema generating
 * color variations based on Opacity, Dark and Light modifications, each
 * variation contains nine levels increasing the or decreasing the property that
 * affects to the color generation
 *
 * - Opacity : A[Intensity]
 *
 * - Dark    : D[Intensity]
 *
 * - Light   : L[Intensity]
 *
 * and also we have the default color that we used to generate the color
 * So we can use the colors the below way:
 *
 * - primary - Default color
 *
 * - primary.A9 - Apply 0.9 opacity to the color
 *
 * - primary.A1 - Apply 0.1 opacity to the color
 *
 * - primary.D9 - Apply 0.9 intensity darken to the color
 *
 * - primary.D1 - Apply 0.1 intensity darken to the color
 *
 * - primary.L9 - Apply 0.9 intensity lighten to the color
 *
 * - primary.L1 - Apply 0.1 intensity lighten to the color
 *
 */
const ThemeProvider = ({
  children,
  theme: customTheme,
  modes: customModes,
  activeModes: customActiveModes,
}) => {
  const [activeModes, setActiveModes] = useState(customActiveModes);
  const [mixedModes, availableModes] = useMemo(
    () => processModes(customModes, activeModes),
    [customModes, activeModes]
  );
  const finalTheme = useMemo(() => processTheme(customTheme, mixedModes), [
    customTheme,
    mixedModes,
  ]);

  const toggleMode = (mode) => {
    if (activeModes.includes(mode)) {
      return setActiveModes(activeModes.filter((x) => x !== mode));
    }
    return setActiveModes(activeModes.concat(mode));
  };

  const disableAllModes = () => setActiveModes([]);

  const modes = availableModes.reduce(
    (acc, availableMode) => ({
      ...acc,
      [availableMode]: {
        name: availableMode,
        toggle: () => toggleMode(availableMode),
      },
    }),
    {}
  );

  return (
    <ThemeContext.Provider
      value={{
        activeModes,
        toggleMode,
        modes,
        disableAllModes,
      }}
    >
      <Provider theme={finalTheme}>
        <GlobalStyle />
        {Children.only(children)}
      </Provider>
    </ThemeContext.Provider>
  );
};

ThemeProvider.defaultProps = {
  theme: {},
  modes: {},
  activeModes: [],
};

ThemeProvider.propTypes = {
  /** Element to enhance with the theme */
  children: PropTypes.node.isRequired,
  /** Custom theme */
  theme: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.any])
  ),
  /** Style fragments to apply on the theme  */
  modes: PropTypes.objectOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.any])
    )
  ),
  /** Activated modes at the first mount */
  activeModes: PropTypes.arrayOf(PropTypes.string),
};

export const useThemeContext = () => useContext(ThemeContext);

export default ThemeProvider;
