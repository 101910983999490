import PropTypes from "prop-types";
import React from "react";
import globalProps from "../../../props";
import {
  FormControlDefaultProps,
  FormControlPropTypes,
  useFormControl,
} from "../FormControl";
import { SWrapper } from "./styles";

const Label = ({ name, className, ...props }) => {
  const updatedProps = useFormControl(props);

  return (
    <SWrapper {...updatedProps} className={className}>
      {name}
      {updatedProps.isRequired && " *"}
    </SWrapper>
  );
};

Label.defaultProps = {
  ...FormControlDefaultProps,
  ...globalProps.styledComponents.className.defaultProps,
  value: undefined,
  onChange: undefined,
  onKeyDown: undefined,
};

Label.propTypes = {
  ...FormControlPropTypes,
  ...globalProps.styledComponents.className.types,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
};

export default Label;
