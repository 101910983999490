import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Login from "./Login";
import { Wrapper } from "./styles";

const Unlogged = () => {
  return (
    <Wrapper>
      <Switch>
        <Route exact path="/" component={Login} />
        <Redirect to="/" />
      </Switch>
    </Wrapper>
  );
};

Unlogged.displayName = "Unlogged";

export default Unlogged;
