import styled from "@xstyled/styled-components";
import { Button } from "#components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  border-top-style: solid;
  border-top-width: bold;
  border-top-color: border.D3;
`;

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const CartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: bg;
`;

export const FooterWrapper = styled.div`
  display: flex;
  align-items: center;
  min-height: 71px;
  height: 71px;
  padding: sm;
  background-color: fg.D1;
  border-top-style: solid;
  border-top-width: bold;
  border-top-color: border.D3;
`;

export const ProductsCartWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: calc(100vh - 275px);
  overflow: auto;
`;

export const CartInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: sm default;
  min-heigh: 150px;
  height: 150px;
  background-color: bg;
  border-top-style: solid;
  border-top-width: bold;
  border-top-color: border.L1;
`;

export const CartInfoBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: xs 0;
  border-bottom-style: solid;
  border-bottom-width: default;
  border-bottom-color: border.L1;
`;

export const CartInfoTitle = styled.span`
  font-weight: bold;
`;

export const ProductCartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 95%;
  padding: sm sm xs;
  margin: xs;
  border-radius: card;
  background-color: secondary.L4;
`;

export const ProductCartName = styled.span`
  padding-bottom: xs;
  font-size: h4;
`;

export const ProductCartMoreInfo = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: sm;
`;

export const ProductCartQuantityWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100px;
`;

export const ProductCartButton = styled(Button)`
  height: auto;
  padding: xxs xs;
  color: primary;
  border-style: solid;
  border-width: default;
  border-color: primary;

  &:hover {
    text-decoration: none;
    background-color: primary;
    color: text.L9;
  }
`;
