import styled from "@xstyled/styled-components";
import { Button } from "#components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  height: 50px;
  background-color: primary.D2;
  color: bg.L9;
  padding: xs;
`;

export const IconWrapper = styled.div`
  padding: sm;
`;

export const CustomerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 95%;
  padding: sm xs sm sm;
  background-color: bg.L9;
  color: text;
  border-radius: card;
`;

export const CustomerName = styled.span`
  text-align: center;
  font-weight: bold;
`;

export const OrderId = styled.span`
  font-size: sm;
`;

export const ClearCustomerButton = styled(Button)`
  height: auto;
  padding: xxs xs;
  color: ko;
  border-style: solid;
  border-width: default;
  border-color: ko;

  &:hover {
    text-decoration: none;
    background-color: ko;
    color: text.L9;
  }
`;
