import { filter, find, map, path, prop, propEq, toUpper, reject } from "ramda";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  FormSelect,
  Icon,
  LoadingIcon,
  PrintIcon,
  PrintReceipt,
  RemoveCartIcon,
  TrashIcon,
} from "#components";
import { apiService } from "#services";
import { actions } from "#store";
import {
  CartInfoBlock,
  CartInfoTitle,
  CartInfoWrapper,
  CartWrapper,
  FooterWrapper,
  LoadingWrapper,
  ProductCartButton,
  ProductCartMoreInfo,
  ProductCartName,
  ProductCartQuantityWrapper,
  ProductCartWrapper,
  ProductsCartWrapper,
  Wrapper,
} from "./styles";

const OrderComponent = () => {
  const dispatch = useDispatch();
  const componentRef = useRef();

  const cart = useSelector(prop("cart"));
  const order = useSelector(path(["order", "create"]));
  const settings = useSelector(path(["cart", "settings", "item"]));
  const settingsLoaded = useSelector(path(["cart", "settings", "loaded"]));
  const settingsError = useSelector(path(["cart", "settings", "error"]));

  const cartHasProducts = order.loaded && cart.products.length > 0;
  useEffect(() => {
    dispatch(actions.creators.order.createClear());
  }, [dispatch, cartHasProducts]);

  const handlerRemove = (id) => {
    dispatch(actions.creators.cart.removeItem({ productId: id }));
  };

  const handlerUpdate = (id, newQuantity) => {
    dispatch(
      actions.creators.cart.updateItem({ productId: id, quantity: newQuantity })
    );
  };

  const handlerClearCart = () => {
    dispatch(actions.creators.cart.clearCart());
  };

  const handlerUpdateStatus = (event) => {
    dispatch(actions.creators.cart.changeStatus(event.target.value));
  };

  const handlerUpdateShippingMethod = (event) => {
    dispatch(actions.creators.cart.changeShippingMethod(event.target.value));
  };

  const handlerRemoveCouponCart = (code) => {
    dispatch(actions.creators.cart.removeCoupon(code));
  };

  const handlerCreateOrUpdateOrder = () => {
    const shippingMethodInfo = find(
      propEq("instanceId", cart.shippingMethod),
      settings.shipping.methods
    );

    if (!shippingMethodInfo) {
      dispatch(
        actions.creators.notifications.newError(
          "Se seleccionó un método de envío no válido, verifique e intente nuevamente"
        )
      );
      return;
    }

    if (cart.order) {
      apiService.order.update({
        resolvers: {
          success: (payload) => {
            handlerClearCart();
            dispatch(actions.creators.order.updateOrder(payload));
          },
        },
        actionsCreator: {
          success: (payload) => ({
            type: actions.types.order.createSuccess,
            payload,
          }),
        },
        urlParameters: {
          orderId: cart.order.id,
        },
        parameters: {
          status: cart.status,
          products:
            ["on-hold", "pending"].includes(cart.order.status) &&
            ["on-hold", "pending"].includes(cart.status)
              ? map((product) => {
                  const formattedProducts = { ...product };
                  delete formattedProducts.taxes;
                  return formattedProducts;
                }, cart.products)
              : undefined,
          coupons: cart.coupons,
          shippingMethod: {
            id: cart.order.shippingMethod.id,
            methodId: shippingMethodInfo.methodId,
            methodTitle: shippingMethodInfo.methodTitle.replace(
              /^([A-Za-zÀ-ÖØ-öø-ÿ0-9\s]+)\s\([A-Za-zÀ-ÖØ-öø-ÿ0-9\s]+\)$/,
              "$1"
            ),
            instanceId: shippingMethodInfo.instanceId,
            price: shippingMethodInfo.price,
          },
        },
        notifications: {
          success: true,
          error: true,
        },
      });
    } else {
      apiService.order.create({
        resolvers: {
          success: () => {
            handlerClearCart();
          },
        },
        actionsCreator: {
          success: (payload) => ({
            type: actions.types.order.createSuccess,
            payload,
          }),
        },
        parameters: {
          status: cart.status,
          customerId: cart.customer && cart.customer.id ? cart.customer.id : 0,
          paymentMode: 1,
          products: cart.products,
          shippingMethod: {
            methodId: shippingMethodInfo.methodId,
            methodTitle: shippingMethodInfo.methodTitle.replace(
              /^([A-Za-zÀ-ÖØ-öø-ÿ0-9\s]+)\s\([A-Za-zÀ-ÖØ-öø-ÿ0-9\s]+\)$/,
              "$1"
            ),
            instanceId: shippingMethodInfo.instanceId,
            price: shippingMethodInfo.price,
          },
          coupons: cart.coupons,
        },
        notifications: {
          success: true,
          error: true,
        },
      });
    }
  };

  const handlerRefundOrder = () => {
    if (cart.order) {
      apiService.order.createRefund({
        resolvers: {
          success: (payload) => {
            handlerClearCart();
            dispatch(actions.creators.order.updateOrder(payload));
          },
        },
        parameters: {
          orderId: cart.order.id,
          products: filter(propEq("order", true), cart.products),
        },
        notifications: {
          success: true,
          error: true,
        },
      });
    }
  };

  if (settingsError && settingsLoaded) {
    return "Contacte con soporte";
  }

  if (!settingsLoaded) {
    return (
      <Wrapper>
        <LoadingWrapper>
          <Icon
            IconComponent={LoadingIcon}
            stroke="border.default"
            width="100px"
            height="100px"
          />
        </LoadingWrapper>
      </Wrapper>
    );
  }

  const allowedToUpdate =
    !cart.order ||
    (cart.order && ["on-hold", "pending"].includes(cart.order.status));

  return (
    <Wrapper>
      <CartWrapper>
        <ProductsCartWrapper>
          <>
            {map(
              ({ productId, name, quantity, total }) => (
                <ProductCartWrapper key={`cartProduct${productId}`}>
                  <ProductCartName>{name}</ProductCartName>
                  <ProductCartMoreInfo>
                    <ProductCartQuantityWrapper>
                      <ProductCartButton
                        variant="link"
                        shape="circle"
                        label="-"
                        onClick={() => handlerUpdate(productId, quantity - 1)}
                        isDisabled={!allowedToUpdate && !cart.isRefundProcess}
                      />

                      <span>{quantity}</span>
                      <ProductCartButton
                        variant="link"
                        shape="circle"
                        label="+"
                        onClick={() => handlerUpdate(productId, quantity + 1)}
                        isDisabled={!allowedToUpdate}
                      />
                    </ProductCartQuantityWrapper>
                    <span>
                      {`${total.toFixed(2)} `}
                      {settings.currencySymbol}
                    </span>

                    <Button
                      variant="link"
                      size="sm"
                      shape="circle"
                      icon={RemoveCartIcon}
                      iconColor="ko"
                      onClick={() => handlerRemove(productId)}
                      isDisabled={!allowedToUpdate && !cart.isRefundProcess}
                    />
                  </ProductCartMoreInfo>
                </ProductCartWrapper>
              ),
              reject(propEq("quantity", 0), cart.products)
            )}
            {map(
              ({ id: couponId, code: couponCode }) => (
                <ProductCartWrapper key={`cartCoupon${couponId}`}>
                  <ProductCartMoreInfo>
                    <ProductCartName>
                      Cupón: <b>{toUpper(couponCode)}</b>
                    </ProductCartName>
                    <Button
                      variant="link"
                      size="sm"
                      shape="circle"
                      icon={TrashIcon}
                      iconColor="ko"
                      onClick={() => handlerRemoveCouponCart(couponCode)}
                      isDisabled={!allowedToUpdate}
                    />
                  </ProductCartMoreInfo>
                </ProductCartWrapper>
              ),
              cart.coupons
            )}
            {cart.products.length > 0 && (
              <>
                <ProductCartWrapper key="cartOrderStatus">
                  <FormSelect
                    name="orderStatus"
                    label="Estado del pedido"
                    value={cart.status}
                    options={map(
                      (status) => ({
                        value: status.id,
                        title: status.title,
                      }),
                      settings.orders.status
                    )}
                    onChange={handlerUpdateStatus}
                    height="formSM"
                    width="100%"
                    required={false}
                    disabled={cart.isRefundProcess}
                  />
                </ProductCartWrapper>
                <ProductCartWrapper key="cartShippingMethod">
                  <FormSelect
                    name="shippingMethod"
                    label="Tipo de envío"
                    value={cart.shippingMethod}
                    options={map(
                      (method) => ({
                        value: method.instanceId,
                        title: method.methodTitle,
                      }),
                      settings.shipping.methods
                    )}
                    onChange={handlerUpdateShippingMethod}
                    height="formSM"
                    width="100%"
                    required={false}
                    disabled={!allowedToUpdate}
                  />
                </ProductCartWrapper>
              </>
            )}
          </>
        </ProductsCartWrapper>
        <CartInfoWrapper>
          <CartInfoBlock>
            <CartInfoTitle>Subtotal</CartInfoTitle>
            <span>
              {`${parseFloat(cart.subtotal).toFixed(2)} `}
              {settings.currencySymbol}
            </span>
          </CartInfoBlock>
          <CartInfoBlock>
            <CartInfoTitle>Envío</CartInfoTitle>
            {`${parseFloat(cart.shippingTotal).toFixed(2)} ${
              settings.currencySymbol
            }`}
          </CartInfoBlock>
          <CartInfoBlock>
            <CartInfoTitle>I.V.A.</CartInfoTitle>
            <span>
              {`${parseFloat(cart.tax).toFixed(2)} `}
              {settings.currencySymbol}
            </span>
          </CartInfoBlock>
        </CartInfoWrapper>
      </CartWrapper>
      <FooterWrapper>
        <Button
          variant="ko"
          size="default"
          shape="square"
          icon={TrashIcon}
          onClick={handlerClearCart}
          isDisabled={cart.products.length === 0 && cart.coupons.length === 0}
        />
        {cart.products.length === 0 && !order.error && order.loaded && (
          <PrintReceipt
            variant="primary"
            size="default"
            shape="square"
            icon={PrintIcon}
            componentRef={componentRef}
            isCartOrder
            onClick={() => {
              dispatch(actions.creators.order.createClear());
            }}
          />
        )}
        {cart.isRefundProcess && (
          <Button
            width="block"
            variant="secondary"
            label={`Reembolso: ${parseFloat(cart.total).toFixed(2)} €`}
            onClick={handlerRefundOrder}
          />
        )}

        {!cart.isRefundProcess && (
          <Button
            width="block"
            variant="ok"
            label={`${parseFloat(cart.total).toFixed(2)} €`}
            onClick={handlerCreateOrUpdateOrder}
            isDisabled={cart.products.length === 0}
          />
        )}
      </FooterWrapper>
    </Wrapper>
  );
};

const OrderComponentContainer = () => {
  useEffect(() => {
    apiService.setting.fetch({
      actionsCreator: {
        start: () => ({ type: actions.types.cart.fetchSettings }),
        success: (payload) => ({
          type: actions.types.cart.fetchSettingsSuccess,
          payload,
        }),
        error: () => ({ type: actions.types.cart.fetchSettingsError }),
      },
      notifications: {
        error: true,
        errorMessage:
          "Error cargando los ajustes, por favor pongasé en contacto con soporte",
      },
    });
  }, []);

  return <OrderComponent />;
};

OrderComponent.displayName = "OrderComponent";

OrderComponentContainer.displayName = "OrderComponentContainer";

export default OrderComponentContainer;
