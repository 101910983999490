import { mergeLeft } from "ramda";
import { generateReducer } from "reduxt";
import actions from "../actions";

const initialState = {
  all: {
    error: false,
    loaded: false,
    requesting: false,
    items: [],
  },
};

const fetchAllFn = (state) => ({
  all: mergeLeft(
    {
      error: false,
      loaded: false,
      requesting: true,
    },
    state.all
  ),
});

const fetchAllSuccessFn = (state, { payload }) =>
  mergeLeft(
    {
      all: mergeLeft(
        {
          loaded: true,
          requesting: false,
          items: payload,
        },
        state.all
      ),
    },
    state
  );

const fetchAllErrorFn = (state) =>
  mergeLeft(
    {
      all: mergeLeft(
        {
          loaded: true,
          error: true,
          requesting: false,
          items: [],
        },
        state.all
      ),
    },
    state
  );

const reducer = generateReducer(initialState, {
  [actions.types.coupon.fetchAll]: fetchAllFn,
  [actions.types.coupon.fetchAllSuccess]: fetchAllSuccessFn,
  [actions.types.coupon.fetchAllError]: fetchAllErrorFn,
});

export default reducer;
