const colorTransformer = (value) => {
  if (typeof value === "object") {
    return value.default;
  }
  return value;
};

export default {
  color: colorTransformer,
};
