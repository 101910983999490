import PropTypes from "prop-types";
import { find, map, propEq, toLower } from "ramda";
import React from "react";
import { FormInput, FormSelect } from "#components";
import ErrorMessage from "./ErrorMessage";
import { FormImage, FormTitle, FormWrapper } from "./styles";

const FormGenerator = ({
  formData,
  handlerFormData,
  title,
  image,
  children,
}) => {
  const handlerChangeData = (event) => {
    const newFieldsData = map((fieldData) => {
      if (fieldData.name !== event.target.name) {
        return fieldData;
      }
      return {
        ...fieldData,
        value: event.target.value,
      };
    }, formData);
    handlerFormData(newFieldsData);
  };

  const hasErrors = find(propEq("error", true), formData);

  return (
    <FormWrapper>
      {!image && title && <FormTitle>{title}</FormTitle>}
      {image && (
        <FormTitle>
          <FormImage src={image} />
          {title || null}
        </FormTitle>
      )}
      {hasErrors && (
        <ErrorMessage text="All fields are required, please complete it" />
      )}
      {map((fieldInfo) => {
        if (toLower(fieldInfo.component) === "select") {
          return (
            <FormSelect
              key={fieldInfo.name}
              name={fieldInfo.name}
              label={fieldInfo.label}
              options={fieldInfo.options}
              value={fieldInfo.value}
              onChange={(event) => {
                if (fieldInfo.event) {
                  fieldInfo.event(event);
                }
                handlerChangeData(event);
              }}
              error={fieldInfo.error}
              required={fieldInfo.required}
              disabled={fieldInfo.disabled}
            />
          );
        }
        if (toLower(fieldInfo.component) === "input") {
          return (
            <FormInput
              key={fieldInfo.name}
              name={fieldInfo.name}
              label={fieldInfo.label}
              type={fieldInfo.type}
              value={fieldInfo.value}
              error={fieldInfo.error}
              onChange={(event) => {
                if (fieldInfo.event) {
                  fieldInfo.event(event);
                }
                handlerChangeData(event);
              }}
              required={fieldInfo.required}
              disabled={fieldInfo.disabled}
            />
          );
        }
        return null;
      }, formData)}
      {children}
    </FormWrapper>
  );
};

export default FormGenerator;

FormGenerator.displayName = "FormGenerator";

FormGenerator.defaultProps = {
  title: undefined,
  image: undefined,
  formData: [],
};

FormGenerator.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
  formData: PropTypes.arrayOf(
    PropTypes.shape({
      component: PropTypes.oneOf(["input", "select"]).isRequired,
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      type: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          title: PropTypes.string,
        })
      ),
      value: PropTypes.string.isRequired,
      event: PropTypes.func,
      error: PropTypes.bool,
      required: PropTypes.bool,
    })
  ),
  handlerFormData: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};
