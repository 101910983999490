import styled, { Box, css } from "@xstyled/styled-components";

/**
 * This components allow the construction of a custom dropdown (along with reakit's Menu).
 * You'll probably never need to use them directly, as the Dropdown component allows per item
 * customization, however it is possible for some rare cases.
 */

export const SWrapper = styled.div`
  width: fit-content;
  position: relative;
  cursor: pointer;
  margin: xs;
  font-family: default;

  > [role="menu"] {
    outline: none;
  }
`;

export const SDropdownButton = styled(Box)`
  border-style: solid;
  display: flex;
  align-items: center;
  justify-content: space-between;
  outline: none;
  position: relative;
  width: fit-content;
  min-width: 130px;

  background-color: fg.default;
  border-width: default;
  border-radius: default;
  border-color: border.default;
  height: form;
  padding: 0 xs 0 sm;
  font-size: default;
  transition: default;

  &:hover {
    box-shadow: default;
  }

  &:active,
  &:focus {
    outline: none;
    box-shadow: none;
    transition: fast;
  }

  ${({ disabled }) =>
    css({
      opacity: disabled ? "0.7" : "1",
      color: disabled ? "border.default" : "text.default",
      cursor: disabled ? "not-allowed" : "pointer",
    })};
`;

export const SCustomButton = styled(SDropdownButton)`
  border: none;
  background: none;
  box-shadow: none;

  &:hover {
    box-shadow: none;
  }
`;

export const SCaret = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  transition: default;
  background: none;

  ${({ isOpen }) =>
    css({
      transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
    })};

  & svg {
    height: 22px;
    background: none;
  }
`;

export const SDropdownBox = styled(Box)`
  width: 100%;
  border-style: solid;
  overflow: hidden;
  max-height: 280px;
  overflow-y: auto;

  border-width: default;
  border-color: border.default;
  box-shadow: smooth;
  border-radius: card;
  z-index: topSection;

  &:hover {
    box-shadow: default;
  }
`;

export const SDropdownItem = styled(SDropdownButton)`
  border-radius: 0;
  border: none;
  border-top: solid;
  position: relative;
  width: 100%;

  border-color: border.default;
  border-width: default;
  transition: default;

  &:after {
    content: "";
    display: none;
    position: absolute;
    left: 0;
    height: 100%;
    transition: default;
  }

  &:hover {
    background-color: hover.default;

    &:after {
      content: "";
      display: block;
    }
  }

  &:first-of-type {
    border: none;
  }
`;
