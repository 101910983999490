import styled from "@xstyled/styled-components";
import {
  alignItems,
  fontSize,
  justifyContent,
  minWidth,
  width,
} from "@xstyled/system";

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const FlexRowWrapper = styled.div`
  display: flex;
  width: 100&;
  margin: xxs xxs xs;
`;

export const FlexRowSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: xs sm;
  background-color: bg.L9;
  box-shadow: smooth;
  font-size: md;

  ${alignItems};
  ${justifyContent};
  ${fontSize};
`;

export const FlexRowSectionTitle = styled.span`
  font-weight: bold;
  text-align: center;
  padding: xs xxs;
`;

export const FlexRowSectionText = styled.span`
  padding: xxs;
`;

export const TableWrapper = styled.div`
  display: flex;
  border-width: default;
  border-style: solid;
  border-color: border;
`;

export const TableColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${width}
  ${minWidth}
`;

export const TableContent = styled.div`
  font-size: md;
  text-align: center;
  padding: xxs;

  &:first-of-type {
    font-weight: bold;
    padding: xs;
    border-bottom-width: default;
    border-bottom-style: solid;
    border-bottom-color: border;
  }
`;
