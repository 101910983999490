import PropTypes from "prop-types";
import React from "react";
import Label from "./Label";
import { StyledFormControl, StyledSelect } from "./styles";

const FormSelect = ({
  name,
  label,
  options,
  value,
  onChange,
  error,
  required,
  width,
  ...props
}) => (
  <StyledFormControl isRequired={required} isInvalid={error} width={width}>
    {label && <Label name={label} />}
    <StyledSelect
      name={name}
      value={value}
      autocomplete="off"
      options={[{ value: "", title: "Seleccione una opción" }, ...options]}
      onChange={onChange}
      width={width}
      {...props}
    />
  </StyledFormControl>
);

export default FormSelect;

FormSelect.displayName = "FormSelect";

FormSelect.defaultProps = {
  label: undefined,
  options: [],
  error: false,
  required: true,
  width: undefined,
};

FormSelect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      title: PropTypes.string,
    })
  ),
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
  required: PropTypes.bool,
  width: PropTypes.string,
};
