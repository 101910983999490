import moment from "moment";
import "moment/locale/es";
import React from "react";
import { Redirect, Switch } from "react-router-dom";
import { LoggedRoute, UnloggedRoute } from "#components";
import Logged from "./Logged";
import Unlogged from "./Unlogged";

const Pages = () => {
  moment.locale("es");

  return (
    <Switch>
      <UnloggedRoute exact path="/" component={Unlogged} />
      <LoggedRoute path="/" component={Logged} />
      <Redirect to="/" />
    </Switch>
  );
};

Pages.displayName = "Pages";

export default Pages;
