import {
  divide,
  filter,
  map,
  multiply,
  path,
  prop,
  propEq,
  sum,
  uniqBy,
} from "ramda";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { actions } from "#store";
import { apiService } from "#services";
import {
  AddIcon,
  ArrowBackIcon,
  Button,
  Icon,
  LoadingIcon,
  MainLayout,
  PreviewIcon,
  PrintIcon,
  PrintReceipt,
  PrintReceiptTemplate,
  RemoveIcon,
} from "#components";
import CustomProductInput from "./CustomProductInput";
import {
  ActionsWrapper,
  InfoIcon,
  InfoWrapper,
  MainWrapper,
  ProductWrapper,
  ReceiptWrapper,
  Wrapper,
} from "./styles";

const CustomReceiptProducts = () => {
  const componentRef = useRef();
  const dispatch = useDispatch();

  const customOrder = useSelector(path(["order", "customOrder", "item"]));

  const [numberProducts, setNumberProducts] = useState(1);
  const [seePreview, setSeePreview] = useState(false);

  const defaultProductObject = {
    productId: 1,
    quantity: "1",
    name: "",
    price: "0",
    tax: "0",
  };

  const [products, setProducts] = useState([defaultProductObject]);

  const fillCustomOrder = () => {
    const formattedProducts = map(
      (product) => ({
        productId: parseInt(product.productId, 10),
        name: product.name,
        quantity: parseInt(product.quantity, 10),
        price: parseFloat(product.price),
        total: multiply(product.price, product.quantity).toFixed(2),
      }),
      products
    );

    const totalProducts = sum(map(prop("total"), formattedProducts));

    const taxLines = map(prop("tax"), uniqBy(prop("tax"), products));

    dispatch(
      actions.creators.order.customOrder({
        ...customOrder,
        customerId: 0,
        customerNote: null,
        shippingLines: null,
        products: formattedProducts,
        total: totalProducts.toFixed(2),
        taxLines: map(
          (tax) => ({
            id: parseFloat(tax),
            label: `I.V.A ${tax}%`,
            total: multiply(
              sum(
                map(
                  (product) => multiply(product.price, product.quantity),
                  filter(propEq("tax", tax), products)
                )
              ),
              divide(tax, 100)
            ).toFixed(2),
          }),
          taxLines
        ),
      })
    );
  };

  const inputsProducts = [];

  for (
    let numberProduct = 0;
    numberProduct < numberProducts;
    numberProduct += 1
  ) {
    inputsProducts.push(
      <ProductWrapper key={`customProduct${products[numberProduct].productId}`}>
        <CustomProductInput
          label="Cantidad"
          products={products}
          setProducts={setProducts}
          numberProduct={numberProduct}
          keyName="quantity"
        />
        <CustomProductInput
          label="Nombre"
          products={products}
          setProducts={setProducts}
          numberProduct={numberProduct}
          keyName="name"
        />
        <CustomProductInput
          label="Precio"
          products={products}
          setProducts={setProducts}
          numberProduct={numberProduct}
          keyName="price"
        />
        <CustomProductInput
          label="I.V.A"
          products={products}
          setProducts={setProducts}
          numberProduct={numberProduct}
          keyName="tax"
        />
      </ProductWrapper>
    );
  }

  return (
    <>
      <MainWrapper display={seePreview ? "none" : null}>
        {inputsProducts}
      </MainWrapper>
      <MainWrapper display={seePreview ? null : "none"}>
        <InfoWrapper>
          <InfoIcon />
          <span>Los datos de la tienda sólo se verán en la impresión</span>
        </InfoWrapper>
        <ReceiptWrapper>
          <PrintReceiptTemplate order={customOrder} />
        </ReceiptWrapper>
      </MainWrapper>
      <ActionsWrapper>
        {!seePreview && (
          <Button
            variant="ko"
            size="sm"
            shape="square"
            icon={RemoveIcon}
            onClick={() => {
              if (numberProducts === 1) return;
              setNumberProducts(numberProducts - 1);
              const newProducts = products;
              newProducts.pop();
              setProducts([...newProducts]);
            }}
          />
        )}
        <Button
          variant="primary"
          size="sm"
          icon={seePreview ? ArrowBackIcon : PreviewIcon}
          label={seePreview ? "Volver" : "Previsualizar"}
          onClick={() => {
            if (!seePreview) {
              fillCustomOrder();
            }
            setSeePreview(!seePreview);
          }}
        />
        <PrintReceipt
          variant="primary"
          size="sm"
          icon={PrintIcon}
          label="Imprimir"
          componentRef={componentRef}
          isCustomOrder
          onClick={() => {
            if (!seePreview) {
              fillCustomOrder();
            }
          }}
        />
        {!seePreview && (
          <Button
            variant="ok"
            size="sm"
            shape="square"
            icon={AddIcon}
            onClick={() => {
              setNumberProducts(numberProducts + 1);
              setProducts([
                ...products,
                {
                  ...defaultProductObject,
                  productId: numberProducts + 1,
                },
              ]);
            }}
          />
        )}
      </ActionsWrapper>
    </>
  );
};

const CustomReceiptProductsLoaded = () => {
  const { orderId } = useParams();
  const dispatch = useDispatch();

  const order = useSelector(path(["order", "one", "item"]));
  const isLoaded = useSelector(path(["order", "one", "loaded"]));
  const isError = useSelector(path(["order", "one", "error"]));
  const isLoading = useSelector(path(["order", "one", "requesting"]));

  const isCustomOrderLoaded = useSelector(
    path(["order", "customOrder", "loaded"])
  );

  useEffect(() => {
    if (!isError && isLoaded) {
      dispatch(actions.creators.order.customOrder(order));
    }
  }, [dispatch, order, isError, isLoaded]);

  if (isError && isLoaded) {
    return <Redirect to="/" />;
  }

  return (
    <MainLayout
      headerIcon={ArrowBackIcon}
      headerIconLink={`/order/${orderId}`}
      header={<></>}
    >
      <Wrapper>
        {!isLoaded || (isLoading && !isLoaded) ? (
          <Icon
            IconComponent={LoadingIcon}
            stroke="border.default"
            width="100px"
            height="100px"
          />
        ) : (
          isCustomOrderLoaded && <CustomReceiptProducts />
        )}
      </Wrapper>
    </MainLayout>
  );
};

const CustomReceiptProductsContainer = () => {
  const { orderId } = useParams();
  const history = useHistory();

  if (/^\d+$/.test(orderId) === false) {
    history.goBack();
  }

  useEffect(() => {
    apiService.order.fetchOne({
      actionsCreator: {
        start: () => ({ type: actions.types.order.fetchOne }),
        success: (payload) => ({
          type: actions.types.order.fetchOneSuccess,
          payload,
        }),
        error: () => ({ type: actions.types.order.fetchOneError }),
      },
      urlParameters: {
        orderId,
      },
      notifications: {
        error: true,
      },
    });
  });

  return <CustomReceiptProductsLoaded />;
};

CustomReceiptProducts.displayName = "CustomReceiptProducts";

CustomReceiptProductsContainer.displayName = "CustomReceiptProductsContainer";

export default CustomReceiptProductsContainer;
