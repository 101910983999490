import styled, { css } from "@xstyled/styled-components";
import { Radio } from "reakit";
import shortid from "shortid";

export const SWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  position: relative;
  width: fit-content;
  margin-right: default;
`;
export const SContent = styled.div`
  width: fit-content;

  height: form;

  ${({ isDisabled }) =>
    css({
      opacity: isDisabled ? "0.5" : "1",
    })};
`;

export const SMask = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  ${({ isDisabled }) =>
    css({
      display: isDisabled ? "block" : "none",
      userSelect: isDisabled ? "none" : "all",
      cursor: isDisabled ? "not-allowed" : "initial",
    })};
`;

export const SLabel = styled.label`
  display: flex;
  align-items: center;
  height: 100%;
`;

export const SRadioButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-style: solid;
  position: relative;
  pointer-events: none;

  border-width: default;
  border-radius: 100%;
  margin-right: xs;
  transition: default;
  height: formXS;
  width: formXS;
  min-height: formXS;
  min-width: formXS;
  background-color: fg;

  ${({ isChecked }) =>
    css({
      borderColor: isChecked ? "primary" : "border",
    })};
  ${({ isHover }) =>
    css({
      boxShadow: isHover ? "default" : "none",
    })};
`;

export const SInnerCircle = styled.div`
  border-width: lg;
  border-style: solid;
  border-color: primary;
  background-color: bg;
  height: 65%;
  width: 65%;
  border-radius: 50%;
`;

export const SText = styled.span`
  transition: default;
  font-size: md;

  ${({ isChecked }) =>
    css({
      fontWeight: isChecked ? "bold" : "normal",
    })};
`;
export const SRealInput = styled(Radio)`
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;

  height: form;
`;

SRealInput.defaultProps = {
  id: shortid.generate(),
};

export const SGroupWrapper = styled.div`
  display: flex;
  align-items: center;
`;
