import styled, { css } from "@xstyled/styled-components";

const trackH = "4px";
const thumbD = "20px";

const track = css`
  box-sizing: border-box;
  height: 4px;
  border-style: solid;

  border-radius: card;
  box-shadow: smooth;
  background-color: primary;
  border-color: border;
  border: none;
`;

const trackFill = css`
  ${track};
  height: 6px;

  ${({ disabled }) =>
    css({
      backgroundColor: disabled ? "border" : "primary",
    })};
`;

const fill = css`
  height: ${trackH};
  border-radius: card;

  ${({ disabled }) =>
    css({
      backgroundColor: disabled ? "border" : "primary",
    })};
`;

const thumb = css`
  box-sizing: border-box;
  border-style: solid;
  border-width: default;
  width: ${thumbD};
  height: ${thumbD};
  border-radius: 50%;
  background-color: fg;
  box-shadow: default;

  ${({ disabled }) =>
    css({
      borderColor: disabled ? "border" : "primary.A7",
    })};
`;

export const SWrapper = styled.div`
  display: flex;
  align-items: center;

  height: form;
`;

export const SSlider = styled.input`
  width: 200px;

  &,
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
  }

  &:focus {
    outline: none;
  }

  // Calculate the filled portion of the slider in case value is defined
  --range: calc(var(--max) - var(--min));
  --ratio: calc((var(--val) - var(--min)) / var(--range));
  --sx: calc(0.5 * ${thumbD} + var(--ratio) * (100% - ${thumbD}));

  margin: 0;
  padding: 0;
  height: ${thumbD};
  background: transparent;
  font: 1em/1 arial, sans-serif;

  &::-webkit-slider-runnable-track {
    ${trackFill};
  }

  &::-moz-range-track {
    ${track};
  }

  &::-ms-track {
    ${track};
  }

  &::-moz-range-progress {
    ${fill};
  }

  &::-ms-fill-lower {
    ${fill};
  }

  &::-webkit-slider-thumb {
    margin-top: calc(0.5 * (${trackH} - ${thumbD}));
    ${thumb};
  }

  &::-moz-range-thumb {
    ${thumb};
  }

  &::-ms-thumb {
    margin-top: 0;
    ${thumb};
  }

  &::-ms-tooltip {
    display: none;
  }

  &::-moz-focus-outer {
    border: 0;
  }
  ${(props) =>
    props.direction === "vertical" ? "transform: rotate(-90deg);" : ""}
`;

SSlider.defaultProps = {
  type: "range",
  direction: "horizontal",
};
