import { mergeLeft } from "ramda";
import { generateReducer } from "reduxt";
import actions from "../actions";

const initialState = {
  all: {
    error: false,
    loaded: false,
    requesting: false,
    updating: false,
    items: [],
  },
  one: {
    error: false,
    loaded: false,
    requesting: false,
    item: {},
  },
};

const fetchAllFn = (state) => ({
  all: mergeLeft(
    {
      error: false,
      loaded: false,
      requesting: true,
      updating: false,
    },
    state.all
  ),
  one: mergeLeft(
    {
      error: false,
      loaded: false,
      requesting: false,
      item: {},
    },
    state.one
  ),
});

const fetchAllSuccessFn = (state, { payload }) =>
  mergeLeft(
    {
      all: mergeLeft(
        {
          loaded: true,
          requesting: false,
          items: payload,
        },
        state.all
      ),
    },
    state
  );

const fetchAllErrorFn = (state) =>
  mergeLeft(
    {
      all: mergeLeft(
        {
          loaded: true,
          error: true,
          requesting: false,
          items: [],
        },
        state.all
      ),
    },
    state
  );

const fetchOneFn = (state) =>
  mergeLeft(
    {
      one: mergeLeft(
        {
          error: false,
          loaded: false,
          requesting: true,
        },
        state.one
      ),
    },
    state
  );

const fetchOneSuccessFn = (state, { payload }) =>
  mergeLeft(
    {
      one: mergeLeft(
        {
          loaded: true,
          requesting: false,
          item: payload,
        },
        state.one
      ),
    },
    state
  );

const fetchOneErrorFn = (state) =>
  mergeLeft(
    {
      one: mergeLeft(
        {
          loaded: true,
          error: true,
          requesting: false,
          item: {},
        },
        state.one
      ),
    },
    state
  );

const reducer = generateReducer(initialState, {
  [actions.types.store.fetchAll]: fetchAllFn,
  [actions.types.store.fetchAllSuccess]: fetchAllSuccessFn,
  [actions.types.store.fetchAllError]: fetchAllErrorFn,
  [actions.types.store.fetchOne]: fetchOneFn,
  [actions.types.store.fetchOneSuccess]: fetchOneSuccessFn,
  [actions.types.store.fetchOneError]: fetchOneErrorFn,
});

export default reducer;
