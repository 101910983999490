import PropTypes from "prop-types";
import React, { createContext, useContext } from "react";
import globalProps from "../../../props";
import { FormControlPropTypes } from "./props";
import { SFormControl } from "./styles";

const FormControlContext = createContext();

export const useFormControl = (props) => {
  const context = useContext(FormControlContext);

  if (!context) {
    return props;
  }

  const newProps = { ...props };
  Object.getOwnPropertyNames(FormControlPropTypes).forEach((key) => {
    newProps[key] =
      !props || props[key] === undefined ? context[key] : props[key];
  });
  return newProps;
};

/**
 *
 * FormControl is connecting to the form context to get the form status and to share it data with the form state.
 *
 * We can use useFormControl to connect own components.
 */
const FormControl = ({
  isInvalid,
  isRequired,
  isDisabled,
  isReadOnly,
  children,
  className,
  ...props
}) => {
  const context = {
    isInvalid,
    isRequired,
    isDisabled,
    isReadOnly,
  };

  return (
    <FormControlContext.Provider value={context}>
      <SFormControl className={className} {...props}>
        {children}
      </SFormControl>
    </FormControlContext.Provider>
  );
};

FormControl.defaultProps = {
  ...globalProps.styledComponents.className.defaultProps,
  isInvalid: undefined,
  isRequired: undefined,
  isDisabled: undefined,
  isReadOnly: undefined,
};

FormControl.propTypes = {
  ...globalProps.styledComponents.className.types,
  isInvalid: PropTypes.bool,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default FormControl;
