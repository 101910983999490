import styled, { css } from "@xstyled/styled-components";
import { color, height, width } from "@xstyled/system";
import {
  AccountCircle,
  Add,
  AddShoppingCart,
  ArrowBack,
  Autorenew,
  Delete as Trash,
  Edit,
  ExitToApp,
  Fullscreen,
  FullscreenExit,
  Group,
  Home,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Person,
  Preview,
  Print,
  ReceiptLong,
  Remove,
  RemoveRedEye,
  RemoveShoppingCart,
  Search,
  ShoppingCart,
  Theaters,
  ViewModule,
} from "styled-icons/material";

const customWidthAndHeight = css`
  width: 20px;
  height: 20px;
  ${width}
  ${height}
  ${color}
`;

export const UserCircleIcon = styled(AccountCircle)`
  ${customWidthAndHeight}
`;

export const HomeIcon = styled(Home)`
  ${customWidthAndHeight}
`;

export const AddCartIcon = styled(AddShoppingCart)`
  ${customWidthAndHeight}
`;

export const RemoveCartIcon = styled(RemoveShoppingCart)`
  ${customWidthAndHeight}
`;

export const CartIcon = styled(ShoppingCart)`
  ${customWidthAndHeight}
`;

export const RefreshIcon = styled(Autorenew)`
  ${customWidthAndHeight}
`;

export const EditIcon = styled(Edit)`
  ${customWidthAndHeight}
`;

export const ExitIcon = styled(ExitToApp)`
  ${customWidthAndHeight}
`;

export const FullscreenIcon = styled(Fullscreen)`
  ${customWidthAndHeight}
`;

export const FullscreenExitIcon = styled(FullscreenExit)`
  ${customWidthAndHeight}
`;

export const ArrowLeftIcon = styled(KeyboardArrowLeft)`
  ${customWidthAndHeight}
`;

export const ArrowRightIcon = styled(KeyboardArrowRight)`
  ${customWidthAndHeight}
`;

export const SearchIcon = styled(Search)`
  ${customWidthAndHeight}
`;

export const ViewIcon = styled(ViewModule)`
  ${customWidthAndHeight}
`;

export const PersonGroupIcon = styled(Group)`
  ${customWidthAndHeight}
`;

export const TrashIcon = styled(Trash)`
  ${customWidthAndHeight}
`;

export const ArrowBackIcon = styled(ArrowBack)`
  ${customWidthAndHeight}
`;

export const EyeIcon = styled(RemoveRedEye)`
  ${customWidthAndHeight}
`;

export const PrintIcon = styled(Print)`
  ${customWidthAndHeight}
`;

export const UserIcon = styled(Person)`
  ${customWidthAndHeight}
`;

export const AddIcon = styled(Add)`
  ${customWidthAndHeight}
`;

export const RemoveIcon = styled(Remove)`
  ${customWidthAndHeight}
`;

export const PreviewIcon = styled(Preview)`
  ${customWidthAndHeight}
`;

export const ReceiptLongIcon = styled(ReceiptLong)`
  ${customWidthAndHeight}
`;

export const CouponIcon = styled(Theaters)`
  transform: rotate(90deg);
  ${customWidthAndHeight};
`;
