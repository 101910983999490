import { path } from "ramda";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "#store";
import { CartIcon } from "#components";
import {
  ClearCustomerButton,
  CustomerName,
  CustomerWrapper,
  IconWrapper,
  OrderId,
  Wrapper,
} from "./styles";

export const OrderHeader = () => {
  const cartCustomerName = useSelector(path(["cart", "customer", "name"]));
  const cartOrderId = useSelector(path(["cart", "order", "orderNumber"]));
  const dispatch = useDispatch();

  const handlerClearCustomer = () => {
    dispatch(actions.creators.cart.clearCustomer());
  };

  return (
    <Wrapper>
      <IconWrapper>
        <CartIcon />
      </IconWrapper>
      {cartCustomerName && (
        <CustomerWrapper>
          <CustomerName>
            {cartCustomerName}
            {cartOrderId && <OrderId> (#{cartOrderId})</OrderId>}
          </CustomerName>
          {!cartOrderId && (
            <ClearCustomerButton
              variant="link"
              shape="circle"
              label="x"
              onClick={handlerClearCustomer}
            />
          )}
        </CustomerWrapper>
      )}
    </Wrapper>
  );
};

OrderHeader.displayName = "OrderHeader";

export default OrderHeader;
