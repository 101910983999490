import moment from "moment";
import PropTypes from "prop-types";
import { find, map, sum, toUpper } from "ramda";
import React, { Component } from "react";
import {
  CompanyName,
  CustomerAddressInfo,
  HeaderWrapper,
  ReceiptAddressInfo,
  ReceiptInfo,
  StyledImage,
  TableData,
  TableHead,
  TableRowBody,
  TableRowHead,
  TableWrapper,
  TaxItem,
  TaxText,
  TaxWrapper,
  TotalText,
  TotalWrapper,
  Wrapper,
} from "./styles";

// eslint-disable-next-line react/prefer-stateless-function
class PrintReceiptTemplate extends Component {
  render() {
    const { order, store } = this.props;

    const oneShippingLine =
      order.shippingLines &&
      find(
        (shippingLine) =>
          ["free_shipping", "flat_rate"].includes(shippingLine.methodId),
        order.shippingLines
      );

    const products = oneShippingLine
      ? [
          ...order.products,
          {
            productId: "shipMethod",
            quantity: 1,
            name: "Envío a domicilio",
            price: parseFloat(
              sum([oneShippingLine.total, oneShippingLine.totalTax])
            ),
            total: parseFloat(
              sum([oneShippingLine.total, oneShippingLine.totalTax])
            ).toFixed(2),
          },
        ]
      : order.products;

    return (
      <Wrapper>
        <HeaderWrapper alignItems="center">
          {store.logoUrl && (
            <StyledImage src={store.logoUrl} alt={store.name} />
          )}

          {!store.logoUrl && store.name && (
            <CompanyName>{store.name}</CompanyName>
          )}

          {store.address1 && (
            <ReceiptAddressInfo>{store.address1}</ReceiptAddressInfo>
          )}
          {store.address2 && (
            <ReceiptAddressInfo>{store.address2}</ReceiptAddressInfo>
          )}
          {store.phone && (
            <ReceiptAddressInfo>TLF. {store.phone}</ReceiptAddressInfo>
          )}
          {store.identType && store.identNumber && (
            <ReceiptAddressInfo>
              {toUpper(store.identType)}: {store.identNumber}
            </ReceiptAddressInfo>
          )}
          <br />
          <ReceiptInfo>
            {`Factura. Simp. ${order.orderNumber} - ${moment(
              order.creationDate
            ).format("DD-MM-YYYY HH:mm")}`}
          </ReceiptInfo>
        </HeaderWrapper>
        <TableWrapper>
          <thead>
            <TableRowHead>
              <TableHead>Ud</TableHead>
              <TableHead>Articulo</TableHead>
              <TableHead>Precio</TableHead>
              <TableHead>Total</TableHead>
            </TableRowHead>
          </thead>
          <tbody>
            {order.products &&
              map((product) => {
                const tax =
                  product.taxes && product.taxes.length >= 1
                    ? product.taxes[0].percent
                    : 0;
                const price = product.price * (tax / 100 + 1);
                return (
                  <TableRowBody key={`receiptProduct${product.productId}`}>
                    <TableData textAlign="center" minWidth="40px">
                      {product.quantity}
                    </TableData>
                    <TableData>{product.name}</TableData>
                    <TableData textAlign="center" minWidth="60px">
                      {`${price.toFixed(2)} ${order.currencySymbol}`}
                    </TableData>
                    <TableData textAlign="center" minWidth="60px">
                      {`${parseFloat(price * product.quantity).toFixed(2)} ${
                        order.currencySymbol
                      }`}
                    </TableData>
                  </TableRowBody>
                );
              }, products)}
          </tbody>
        </TableWrapper>

        <TotalWrapper>
          <TotalText>TOTAL</TotalText>
          <TotalText>{`${order.total} ${order.currencySymbol}`}</TotalText>
        </TotalWrapper>

        {order.taxLines && order.taxLines.length !== 0 ? (
          <>
            <TaxWrapper>
              {map(
                (tax) => (
                  <TaxItem key={`taxItem${tax.id}`}>
                    <TaxText>{tax.label}</TaxText>
                    <TaxText>{`${tax.total} ${order.currencySymbol}`}</TaxText>
                  </TaxItem>
                ),
                order.taxLines
              )}
            </TaxWrapper>
            <ReceiptInfo>I.V.A INCLUIDO</ReceiptInfo>
          </>
        ) : null}
        <ReceiptInfo>---- GRACIAS POR SU VISITA ----</ReceiptInfo>

        <HeaderWrapper>
          {order.customerId !== 0 && order.customerName && (
            <ReceiptInfo fontSize="1em">{order.customerName}</ReceiptInfo>
          )}
          {order.paymentMethodTitle && (
            <ReceiptInfo>
              {`Método de pago: ${order.paymentMethodTitle}`}
            </ReceiptInfo>
          )}
          {order.customerNote && (
            <ReceiptInfo>
              <b>Nota del pedido</b>
              <br />
              {order.customerNote}
            </ReceiptInfo>
          )}
          {order.customerId !== 0 && oneShippingLine && (
            <>
              <CustomerAddressInfo textAlign="center" fontWeight="bold">
                Dirección del cliente
              </CustomerAddressInfo>
              <CustomerAddressInfo paddingLeft="xs">{`${order.shipping.address1} ${order.shipping.address2}`}</CustomerAddressInfo>
              <CustomerAddressInfo paddingLeft="xs">{`${order.shipping.postcode} - ${order.shipping.city}`}</CustomerAddressInfo>
              <CustomerAddressInfo paddingLeft="xs">
                {order.billing.phone}
              </CustomerAddressInfo>
            </>
          )}
        </HeaderWrapper>
        {store.endMsg && (
          <ReceiptInfo dangerouslySetInnerHTML={{ __html: store.endMsg }} />
        )}
      </Wrapper>
    );
  }
}

PrintReceiptTemplate.displayName = "PrintReceiptTemplate";

PrintReceiptTemplate.defaultProps = {
  store: {
    logoUrl: undefined,
    name: undefined,
    address1: undefined,
    address2: undefined,
    identType: undefined,
    identNumber: undefined,
    phone: undefined,
    endMsg: undefined,
  },
};

PrintReceiptTemplate.propTypes = {
  order: PropTypes.shape({
    orderNumber: PropTypes.string.isRequired,
    creationDate: PropTypes.string.isRequired,
    products: PropTypes.arrayOf(
      PropTypes.shape({
        productId: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        quantity: PropTypes.number.isRequired,
        price: PropTypes.number.isRequired,
        total: PropTypes.string.isRequired,
      })
    ).isRequired,
    total: PropTypes.string.isRequired,
    customerId: PropTypes.number,
    customerName: PropTypes.string,
    customerNote: PropTypes.string,
    paymentMethodTitle: PropTypes.string,
    taxLines: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        label: PropTypes.string.isRequired,
        total: PropTypes.string.isRequired,
      })
    ),
    shippingLines: PropTypes.arrayOf(
      PropTypes.shape({
        methodId: PropTypes.string,
        total: PropTypes.string.isRequired,
        totalTax: PropTypes.string.isRequired,
      })
    ),
    shipping: PropTypes.shape({
      address1: PropTypes.string,
      address2: PropTypes.string,
      postcode: PropTypes.string,
      city: PropTypes.string,
    }),
    billing: PropTypes.shape({
      phone: PropTypes.string,
    }),
    currencySymbol: PropTypes.string.isRequired,
  }).isRequired,
  store: PropTypes.shape({
    logoUrl: PropTypes.string,
    name: PropTypes.string,
    address1: PropTypes.string,
    address2: PropTypes.string,
    identType: PropTypes.string,
    identNumber: PropTypes.string,
    phone: PropTypes.string,
    endMsg: PropTypes.string,
  }),
};

export default PrintReceiptTemplate;
