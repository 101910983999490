import styled from "@xstyled/styled-components";
import { AddCartIcon } from "#components";

export const CouponsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
  height: calc(100vh - 173px);
  overflow: auto;
`;

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 173px);
  background-color: bg.L9;
  border-radius: card;
`;

export const CouponWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: xxs;
  padding: xs xxs;
  background-color: bg;
  box-shadow: smooth;
  border-radius: default;
  min-height: 56px;
`;

export const CouponInformationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const CouponActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const CouponExpired = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 100px;
  width: 100px;
  background-color: ko.D1;
  color: text.L9;
  border-radius: default;
  margin: xxs sm;
  padding: xs;
  user-select: none;
  font-size: md;
`;

export const CouponCode = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 100px;
  width: 100%;
  background-color: fg.D4;
  color: text.L9;
  border-radius: default;
  margin: xxs sm;
  padding: xs;
  user-select: none;
  font-size: md;
`;

export const StyledAddCartIcon = styled(AddCartIcon)`
  height: 30px;
  width: 30px;
`;
