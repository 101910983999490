import { defaultTo, path, pipe, prop } from "rambda";
import { mapObjectToCamelCase, mapObjectToSnakeCase } from "#utils";

export const getAPIHeaders = ({ api, token, timeout }) => {
  const API_URL = api;
  const TIMEOUT = timeout || 60000;
  const conf = {
    baseURL: API_URL.endsWith("/") ? API_URL : `${API_URL}/`,
    timeout: TIMEOUT,
  };

  if (token) {
    conf.headers = {};
  }

  return conf;
};

export const parseUrl = (url, parameters) => {
  const urlPattern = /\{\{(\w+)\}\}/g;
  if (!url.match(urlPattern)) {
    return url;
  }

  return url.replace(urlPattern, (toReplace) => {
    const key = toReplace.match(/\w+/)[0] || "";
    return path(key, parameters);
  });
};

export const parseResponse = pipe(
  prop("data"),
  defaultTo({}),
  mapObjectToCamelCase
);

export const parseData = pipe(defaultTo({}), mapObjectToSnakeCase);
