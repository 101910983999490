import styled from "@xstyled/styled-components";
import { AddCartIcon, EditIcon } from "#components";

export const CustomersWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: space-evenly;
  width: 100%;
  height: calc(100vh - 173px);
  overflow: auto;
`;

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 173px);
  background-color: bg.L9;
  border-radius: card;
`;

export const CustomerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: xxs;
  padding: xs xxs;
  background-color: bg;
  box-shadow: smooth;
  border-radius: default;
`;

export const CustomerImage = styled.div`
  display: flex;
  align-items: center;
  padding: xs sm;
  min-width: 70px;
  width: 70px;
`;

export const CustomerInformation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

export const CustomerName = styled.span`
  font-weight: bold;
`;

export const CustomerPhoneMail = styled.span`
  font-size: md;
  color: bg.D6;
`;

export const CustomerActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  min-width: 130px;
  width: 130px;
`;

export const StyledEditIcon = styled(EditIcon)`
  height: 30px;
  width: 30px;
`;

export const StyledAddCartIcon = styled(AddCartIcon)`
  height: 30px;
  width: 30px;
`;
