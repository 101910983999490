import React from "react";
import { MainLayout, UserIcon } from "#components";
import { UserName, UserNameWrapper } from "./styles";

const Dashboard = () => {
  const userName = localStorage.getItem("name");
  return (
    <MainLayout>
      <UserNameWrapper>
        <UserIcon width="50px" height="50px" />
        <UserName>
          Bienvenido, <b>{userName}</b>
        </UserName>
      </UserNameWrapper>
    </MainLayout>
  );
};

export default Dashboard;
