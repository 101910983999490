import moment from "moment";
import PropTypes from "prop-types";
import { join, map, path, prop } from "ramda";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { v4 as randomId } from "uuid";
import { actions } from "#store";
import { apiService } from "#services";
import {
  ArrowBackIcon,
  Button,
  Icon,
  LoadingIcon,
  MainLayout,
  PrintIcon,
  PrintReceipt,
  ReceiptLongIcon,
} from "#components";
import {
  FlexRowSection,
  FlexRowSectionText,
  FlexRowSectionTitle,
  FlexRowWrapper,
  HeaderWrapper,
  TableColumnWrapper,
  TableContent,
  TableWrapper,
} from "./styles";

const ViewOrderHeader = ({ orderLoad, componentRef }) => {
  const history = useHistory();
  const { orderId } = useParams();

  return (
    <HeaderWrapper>
      <Button
        variant="link"
        size="sm"
        icon={ReceiptLongIcon}
        label="Ticket personalizado"
        onClick={() => history.push(`/order/${orderId}/custom-receipt`)}
      />
      {orderLoad && (
        <PrintReceipt
          variant="link"
          size="sm"
          shape="square"
          icon={PrintIcon}
          componentRef={componentRef}
        />
      )}
    </HeaderWrapper>
  );
};

const TableColumnGenerator = ({ title, width, data }) => {
  const dataWithTitle = [title, ...data];
  return (
    <TableColumnWrapper width={width} minWidth={width}>
      {map(
        (row) => (
          <TableContent key={randomId()}>{row}</TableContent>
        ),
        dataWithTitle
      )}
    </TableColumnWrapper>
  );
};

const ViewOrder = () => {
  const dispatch = useDispatch();
  const componentRef = useRef();

  const isLoaded = useSelector(path(["order", "one", "loaded"]));
  const isError = useSelector(path(["order", "one", "error"]));
  const isLoading = useSelector(path(["order", "one", "requesting"]));
  const order = useSelector(path(["order", "one", "item"]));

  const cartCustomerName = useSelector(path(["cart", "customer", "name"]));
  const cartProducts = useSelector(path(["cart", "products"]));

  if (isError && isLoaded) {
    return <Redirect to="/" />;
  }

  const handlerAddOrderToCurrentCart = (refund) => {
    dispatch(
      actions.creators.cart.assignOrder({
        customer: {
          id: order.customerId,
          name: order.customerName,
        },
        products: order.products,
        isRefundProcess: refund,
        order: {
          id: order.id,
          orderNumber: order.orderNumber,
          status: order.status,
          statusHumanize: order.statusHumanize,
          coupons: order.couponLines,
          shippingMethod:
            order.shippingLines &&
            order.shippingLines.length > 0 &&
            order.shippingLines[0],
        },
      })
    );
  };

  return (
    <MainLayout
      headerIcon={ArrowBackIcon}
      headerIconLink="/orders"
      header={
        <ViewOrderHeader orderLoad={isLoaded} componentRef={componentRef} />
      }
    >
      {!isLoaded || (isLoading && !isLoaded) ? (
        <FlexRowWrapper>
          <FlexRowSection alignItems="center">
            <Icon
              IconComponent={LoadingIcon}
              stroke="border.default"
              width="50px"
              height="50px"
            />
          </FlexRowSection>
        </FlexRowWrapper>
      ) : (
        <>
          <FlexRowWrapper>
            <FlexRowSection>
              <FlexRowSectionTitle>Información del pedido</FlexRowSectionTitle>
              <FlexRowSectionText>
                Número de pedido: #{order.orderNumber}
              </FlexRowSectionText>
              <FlexRowSectionText>
                Cliente: {order.customerName}
              </FlexRowSectionText>
              {order.statusHumanize && (
                <FlexRowSectionText>
                  Estado: {order.statusHumanize}
                </FlexRowSectionText>
              )}
              <FlexRowSectionText>
                {`Envío: ${join(
                  " / ",
                  map(prop("methodTitle"), order.shippingLines)
                )}`}
              </FlexRowSectionText>
              <FlexRowSectionText>Venta: {order.store}</FlexRowSectionText>
              <FlexRowSectionText>
                Realizado: {moment(order.creationDate).format("ll")}
              </FlexRowSectionText>
            </FlexRowSection>

            <FlexRowSection>
              <FlexRowSectionTitle>
                Información de facturación
              </FlexRowSectionTitle>
              <FlexRowSectionText>{`${order.billing.address1} ${order.billing.address2}`}</FlexRowSectionText>
              <FlexRowSectionText>{`${order.billing.postcode} - ${order.billing.city}`}</FlexRowSectionText>
              <FlexRowSectionText>{order.billing.email}</FlexRowSectionText>
              <FlexRowSectionText>{order.billing.phone}</FlexRowSectionText>
            </FlexRowSection>
          </FlexRowWrapper>
          {order.customerNote && order.customerNote !== "" && (
            <FlexRowWrapper>
              <FlexRowSection>
                <FlexRowSectionTitle>Notas del pedido</FlexRowSectionTitle>
                <FlexRowSectionText>{order.customerNote}</FlexRowSectionText>
              </FlexRowSection>
            </FlexRowWrapper>
          )}
          <FlexRowWrapper>
            <FlexRowSection>
              <FlexRowSectionTitle>Productos</FlexRowSectionTitle>
              <TableWrapper>
                <TableColumnGenerator
                  width="60%"
                  title="Nombre"
                  data={map(prop("name"), order.products)}
                />
                <TableColumnGenerator
                  title="Cantidad"
                  data={map(prop("quantity"), order.products)}
                />
                <TableColumnGenerator
                  title="Precio"
                  data={map(
                    (product) => parseFloat(product.price).toFixed(2),
                    order.products
                  )}
                />
                <TableColumnGenerator
                  title="Subtotal"
                  data={map(prop("total"), order.products)}
                />
              </TableWrapper>
            </FlexRowSection>
          </FlexRowWrapper>
          <FlexRowWrapper>
            <FlexRowSection>
              <FlexRowSectionTitle>Información de pago</FlexRowSectionTitle>
              {order.paymentMethodTitle && (
                <FlexRowSectionText>
                  {`Método de pago: ${order.paymentMethodTitle}`}
                </FlexRowSectionText>
              )}
              {order.paymentMethodInfo &&
                order.paymentMethodInfo.orderNumber && (
                  <FlexRowSectionText>
                    {`Número transacción: ${order.paymentMethodInfo.orderNumber}`}
                  </FlexRowSectionText>
                )}
              {order.paymentMethodInfo && order.paymentMethodInfo.date && (
                <FlexRowSectionText>
                  {`Fecha transacción: ${order.paymentMethodInfo.date}`}
                </FlexRowSectionText>
              )}
              {order.paymentMode && (
                <FlexRowSectionText>
                  {`Modo de pago: ${order.paymentMode}`}
                </FlexRowSectionText>
              )}
              <FlexRowSectionText>
                {`Reembolsado: ${order.totalRefund} €`}
              </FlexRowSectionText>
            </FlexRowSection>
            <FlexRowSection
              alignItems="flex-end"
              justifyContent="center"
              fontSize="default"
            >
              <FlexRowSectionText>
                {`Subtotal: ${order.totalProducts} €`}
              </FlexRowSectionText>
              <FlexRowSectionText>
                {`Impuesto: ${order.totalTax} €`}
              </FlexRowSectionText>
              <FlexRowSectionText>
                Envío: {order.shippingTotal} €
              </FlexRowSectionText>

              <FlexRowSectionText>
                {`Total: ${order.total} €`}
              </FlexRowSectionText>
              {["pending", "on-hold", "processing"].includes(order.status) &&
                ["cod", "redsys"].includes(order.paymentMethod) &&
                !cartCustomerName &&
                cartProducts.length === 0 && (
                  <Button
                    variant="primary"
                    size="sm"
                    width="block"
                    label="Pasar a caja"
                    onClick={() => handlerAddOrderToCurrentCart(false)}
                  />
                )}
              {order.status === "completed" &&
                !cartCustomerName &&
                cartProducts.length === 0 && (
                  <Button
                    variant="secondary"
                    size="sm"
                    width="block"
                    label="Pasar a reembolso"
                    onClick={() => handlerAddOrderToCurrentCart(true)}
                  />
                )}
            </FlexRowSection>
          </FlexRowWrapper>
        </>
      )}
    </MainLayout>
  );
};

const ViewOrderContainer = () => {
  const { orderId } = useParams();

  const history = useHistory();

  if (/^\d+$/.test(orderId) === false) {
    history.goBack();
  }

  useEffect(() => {
    apiService.order.fetchOne({
      actionsCreator: {
        start: () => ({ type: actions.types.order.fetchOne }),
        success: (payload) => ({
          type: actions.types.order.fetchOneSuccess,
          payload,
        }),
        error: () => ({ type: actions.types.order.fetchOneError }),
      },
      urlParameters: {
        orderId,
      },
      notifications: {
        error: true,
      },
    });
  });

  return <ViewOrder />;
};

ViewOrderHeader.displayName = "ViewOrderHeader";

ViewOrderHeader.propTypes = {
  orderLoad: PropTypes.bool.isRequired,
  componentRef: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    PropTypes.shape({ current: PropTypes.oneOfType([PropTypes.any]) }),
  ]).isRequired,
};

TableColumnGenerator.displayName = "TableColumnGenerator";

TableColumnGenerator.defaultProps = {
  width: undefined,
};

TableColumnGenerator.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  width: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired,
};

ViewOrder.displayName = "ViewOrder";

ViewOrderContainer.displayName = "ViewOrderContainer";

export default ViewOrderContainer;
