import axios from "axios";
import { map } from "rambda";
import { is, mergeAll, objOf, pipe } from "ramda";
import store, { actions } from "#store";
import { getAPIHeaders, parseData, parseResponse, parseUrl } from "./utils";

const customDispatch = (input, data) => {
  if (is(Array, input)) {
    return input.forEach((action) => store.dispatch(action(data)));
  }
  return store.dispatch(input(data));
};

const makeRequest = (configService, axiosFn, acceptData) => async ({
  url,
  urlParameters,
  parameters,
  resolvers = {},
  actionsCreator = {},
  notifications = {},
}) => {
  try {
    const config = getAPIHeaders(configService);
    const token = localStorage.getItem("token");
    if (config.headers && token) {
      config.headers.authorization = `Bearer ${token}`;
    }

    const parsedUrl = parseUrl(url, urlParameters);
    const parsedData = configService.snakeParams
      ? parseData(parameters)
      : parameters;

    const requestParams = acceptData
      ? [parsedUrl, parsedData, config]
      : [parsedUrl, config];

    if (resolvers.start) {
      resolvers.start();
    }

    if (actionsCreator.start) {
      customDispatch(actionsCreator.start);
    }

    const response = await axiosFn(...requestParams);
    const parsedResponse = parseResponse(response);

    if (resolvers.success) {
      resolvers.success(parsedResponse.data);
    }

    if (actionsCreator.success) {
      customDispatch(actionsCreator.success, parsedResponse.data);
    }

    if (notifications.success) {
      customDispatch(
        actions.creators.notifications.newSuccess,
        notifications.successMessage || parsedResponse.message
      );
    }
  } catch (err) {
    const parsedResponse = parseResponse(err.response);
    if (resolvers.error) {
      resolvers.error(err);
    }

    if (parsedResponse.code === 401) {
      customDispatch(actions.creators.auth.logout);
    }

    if (notifications.error) {
      customDispatch(
        actions.creators.notifications.newError,
        notifications.errorMessage ||
          parsedResponse.message ||
          "Ha ocurrido un error interno, por favor vuelva a intentarlo."
      );
    }

    if (actionsCreator.error) {
      customDispatch(actionsCreator.error, parsedResponse);
    }
  }
};

const makeService = (serviceConfig) =>
  pipe(
    map(([method, acceptData]) =>
      objOf(method, makeRequest(serviceConfig, axios[method], acceptData))
    ),
    mergeAll
  )([
    ["post", true],
    ["patch", true],
    ["put", true],
    ["get", false],
    ["delete", false],
  ]);

const toServiceHook = (methodFn) => (url) => (config) =>
  methodFn({ url, ...config });

export const makeServiceHooks = (service) => ({
  toPostHook: toServiceHook(service.post),
  toPatchHook: toServiceHook(service.patch),
  toUpdateHook: toServiceHook(service.put),
  toFetchHook: toServiceHook(service.get),
  toDeleteHook: toServiceHook(service.delete),
});

export default makeService;
