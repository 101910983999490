import { map, mergeLeft, prop } from "ramda";
import { generateReducer } from "reduxt";
import actions from "../actions";

const initialState = {
  all: {
    error: false,
    loaded: false,
    requesting: false,
    updating: false,
    items: [],
  },
  one: {
    error: false,
    loaded: false,
    requesting: false,
    item: {},
  },
  create: {
    error: false,
    loaded: false,
    requesting: false,
    item: {},
  },
  customOrder: {
    error: false,
    loaded: false,
    requesting: false,
    item: {},
  },
};

const fetchAllFn = (state) => ({
  all: mergeLeft(
    {
      error: false,
      loaded: false,
      requesting: true,
      updating: false,
    },
    state.all
  ),
  one: mergeLeft(
    {
      error: false,
      loaded: false,
      requesting: false,
      item: {},
    },
    state.one
  ),
  create: state.create,
});

const fetchAllSuccessFn = (state, { payload }) =>
  mergeLeft(
    {
      all: mergeLeft(
        {
          loaded: true,
          requesting: false,
          items: payload,
        },
        state.all
      ),
    },
    state
  );

const fetchAllErrorFn = (state) =>
  mergeLeft(
    {
      all: mergeLeft(
        {
          loaded: true,
          error: true,
          requesting: false,
          items: [],
        },
        state.all
      ),
    },
    state
  );

const fetchOneFn = (state) =>
  mergeLeft(
    {
      one: mergeLeft(
        {
          error: false,
          loaded: false,
          requesting: true,
        },
        state.one
      ),
    },
    state
  );

const fetchOneSuccessFn = (state, { payload }) =>
  mergeLeft(
    {
      one: mergeLeft(
        {
          loaded: true,
          requesting: false,
          item: payload,
        },
        state.one
      ),
    },
    state
  );

const fetchOneErrorFn = (state) =>
  mergeLeft(
    {
      one: mergeLeft(
        {
          loaded: true,
          error: true,
          requesting: false,
          item: {},
        },
        state.one
      ),
    },
    state
  );

const createFn = (state) =>
  mergeLeft(
    {
      create: mergeLeft(
        {
          error: false,
          loaded: false,
          requesting: true,
        },
        state.create
      ),
    },
    state
  );

const createSuccessFn = (state, { payload }) =>
  mergeLeft(
    {
      create: mergeLeft(
        {
          loaded: true,
          requesting: false,
          item: payload,
        },
        state.create
      ),
    },
    state
  );

const createErrorFn = (state) =>
  mergeLeft(
    {
      create: mergeLeft(
        {
          loaded: true,
          error: true,
          requesting: false,
          item: {},
        },
        state.create
      ),
    },
    state
  );

const createClearFn = (state) =>
  mergeLeft(
    {
      create: initialState.create,
    },
    state
  );

const updateOrderFn = (state, { payload }) => {
  const isNewOrder = !map(prop("id"), state.all.items).includes(payload.id);

  return mergeLeft(
    {
      all: mergeLeft(
        {
          items: isNewOrder
            ? [payload, ...state.all.items]
            : map((order) => {
                if (order.id === payload.id) {
                  return payload;
                }
                return order;
              }, state.all.items),
        },
        state.all
      ),
      one: mergeLeft(
        {
          item: payload,
        },
        state.one
      ),
    },
    state
  );
};

const customOrderFn = (state, { payload }) =>
  mergeLeft(
    {
      customOrder: mergeLeft(
        {
          loaded: true,
          requesting: false,
          item: payload,
        },
        state.customOrder
      ),
    },
    state
  );

const reducer = generateReducer(initialState, {
  [actions.types.order.fetchAll]: fetchAllFn,
  [actions.types.order.fetchAllSuccess]: fetchAllSuccessFn,
  [actions.types.order.fetchAllError]: fetchAllErrorFn,
  [actions.types.order.fetchOne]: fetchOneFn,
  [actions.types.order.fetchOneSuccess]: fetchOneSuccessFn,
  [actions.types.order.fetchOneError]: fetchOneErrorFn,
  [actions.types.order.create]: createFn,
  [actions.types.order.createSuccess]: createSuccessFn,
  [actions.types.order.createError]: createErrorFn,
  [actions.types.order.createClear]: createClearFn,
  [actions.types.order.updateOrder]: updateOrderFn,
  [actions.types.order.customOrder]: customOrderFn,
});

export default reducer;
