import styled from "@xstyled/styled-components";
import { NavLink } from "react-router-dom";

export const StyledNavLink = styled(NavLink)`
  color: primary.D3;
  text-decoration: none;
  padding: xs;

  &.active {
    font-weight: bold;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const CustomerName = styled.span`
  font-weight: bold;
`;
