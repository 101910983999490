import PropTypes from "prop-types";
import { path, propEq, reject } from "ramda";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { actions } from "#store";
import { apiService } from "#services";
import { Button } from "#components";
import PrintReceiptTemplate from "./PrintReceiptTemplate";

const PrintReceiptButton = ({
  isCartOrder,
  isCustomOrder,
  onClick,
  componentRef,
  isListOrder,
  order,
  ...props
}) => {
  let orderType = "one";
  if (isCartOrder) {
    orderType = "create";
  }

  if (isCustomOrder) {
    orderType = "customOrder";
  }

  let orderData = useSelector(path(["order", `${orderType}`]));
  if (isListOrder) {
    orderData = {
      error: false,
      loaded: true,
      item: order,
    };
  }

  const store = useSelector(path(["store", "one"]));

  const handlerPrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      <Button
        onClick={() => {
          if (onClick) {
            onClick();
          }
          handlerPrint();
        }}
        {...props}
      />
      {!orderData.error && orderData.loaded && !store.error && store.loaded && (
        <span style={{ display: "none" }}>
          <PrintReceiptTemplate
            ref={componentRef}
            order={{
              ...orderData.item,
              products: reject(propEq("quantity", 0), orderData.item.products),
            }}
            store={store.item}
          />
        </span>
      )}
    </>
  );
};

const PrintReceiptContainer = ({ ...props }) => {
  useEffect(() => {
    apiService.user.fetchProfile({
      actionsCreator: {
        start: () => ({ type: actions.types.store.fetchOne }),
        success: (payload) => ({
          type: actions.types.store.fetchOneSuccess,
          payload: payload.store,
        }),
        error: () => ({ type: actions.types.store.fetchOneError }),
      },
      notifications: {
        error: true,
      },
    });
  });

  return <PrintReceiptButton {...props} />;
};

PrintReceiptContainer.displayName = "PrintReceiptContainer";

PrintReceiptButton.displayName = "PrintReceiptButton";

PrintReceiptButton.defaultProps = {
  onClick: undefined,
  isCartOrder: undefined,
  isCustomOrder: undefined,
  isListOrder: undefined,
  order: {},
};

PrintReceiptButton.propTypes = {
  onClick: PropTypes.func,
  isCartOrder: PropTypes.bool,
  isCustomOrder: PropTypes.bool,
  isListOrder: PropTypes.bool,
  order: PropTypes.shape,
  componentRef: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    PropTypes.shape({ current: PropTypes.oneOfType([PropTypes.any]) }),
  ]).isRequired,
};

export default PrintReceiptContainer;
