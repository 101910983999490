import { map } from "ramda";
import PropTypes from "prop-types";
import React from "react";
import { FormInput } from "#components";

const CustomProductInput = ({
  label,
  products,
  setProducts,
  numberProduct,
  keyName,
}) => (
  <FormInput
    name={`productQuantity${numberProduct}`}
    label={label}
    value={products[numberProduct][keyName]}
    size="sm"
    onChange={(event) => {
      setProducts(
        map((product) => {
          if (product.productId === numberProduct + 1) {
            return {
              ...product,
              [keyName]: event.target.value,
            };
          }
          return product;
        }, products)
      );
    }}
  />
);

CustomProductInput.displayName = "CustomProductInput";

CustomProductInput.propTypes = {
  label: PropTypes.string.isRequired,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      productId: PropTypes.number.isRequired,
    })
  ).isRequired,
  setProducts: PropTypes.func.isRequired,
  numberProduct: PropTypes.number.isRequired,
  keyName: PropTypes.string.isRequired,
};

export default CustomProductInput;
