import { mergeDeepLeft, mergeLeft } from "ramda";
import { generateReducer } from "reduxt";
import actions from "../actions";

const initialState = {
  error: false,
  requesting: false,
};

const authenticateFn = (state) =>
  mergeDeepLeft(
    {
      error: false,
      requesting: true,
    },
    state
  );
const authenticateSuccessFn = (state, { payload }) => {
  return mergeDeepLeft(
    {
      loaded: true,
      requesting: false,
      info: payload,
    },
    state
  );
};

const authenticateErrorFn = mergeLeft({
  error: true,
  requesting: false,
  info: {},
});

const logoutFn = () => {
  localStorage.removeItem("isLoggedIn");
  localStorage.removeItem("name");
  localStorage.removeItem("token");
  return initialState;
};

const reducer = generateReducer(initialState, {
  [actions.types.auth.authenticate]: authenticateFn,
  [actions.types.auth.authenticateSuccess]: authenticateSuccessFn,
  [actions.types.auth.authenticateError]: authenticateErrorFn,
  [actions.types.auth.logout]: logoutFn,
});

export default reducer;
