const sizes = {
  xxs: "4px", // Generic
  xs: "8px", // Generic
  sm: "16px", // Generic
  formXS: "20px", // Form (extraSmall)
  default: "30px", // Generic
  formSM: "32px", // Form (Small)
  form: "40px", // Form
  lg: "50px", // Generic
  xl: "64px", // Generic

  // Form size
  // tokens used for determinate
  // the height of the all Form elements we can use
  // in a generic form like: Inputs, dropdowns,checkBoxes, Buttons ect
  // using the same Heigh for the elements(or jis wrappers)
  // we keep the design consistency

  // Generic
  // size values we can use as heigh or width
  // same values as Space tokens
};

export default sizes;
