import PropTypes from "prop-types";
import { path } from "ramda";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { actions } from "#store";
import { Button, UserCircleIcon } from "#components";
import {
  CustomerActions,
  CustomerImage,
  CustomerInformation,
  CustomerName,
  CustomerPhoneMail,
  CustomerWrapper,
  StyledAddCartIcon,
  StyledEditIcon,
} from "./styles";

const CustomerCard = ({ id, customer, phone, email }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const cartCustomerName = useSelector(path(["cart", "customer", "name"]));

  const handlerEditCustomer = (customerId) => {
    history.push(`/customer/${customerId}`);
  };

  const handlerAddCustomerToCurrentCart = (customerId, customerName) => {
    dispatch(
      actions.creators.cart.assignCustomer({
        id: customerId,
        name: customerName,
      })
    );
  };

  return (
    <CustomerWrapper>
      <CustomerImage>
        <UserCircleIcon width="30px" height="30px" />
      </CustomerImage>
      <CustomerInformation>
        <CustomerName>{customer}</CustomerName>
        <CustomerPhoneMail>{phone}</CustomerPhoneMail>
        <CustomerPhoneMail>{email}</CustomerPhoneMail>
      </CustomerInformation>
      <CustomerActions>
        <Button
          variant="link"
          size="sm"
          icon={StyledEditIcon}
          onClick={() => handlerEditCustomer(id)}
        />
        {!cartCustomerName && (
          <Button
            variant="link"
            size="sm"
            icon={StyledAddCartIcon}
            onClick={() => handlerAddCustomerToCurrentCart(id, customer)}
          />
        )}
      </CustomerActions>
    </CustomerWrapper>
  );
};

CustomerCard.displayName = "CustomerCard";

CustomerCard.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  customer: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
};

export default CustomerCard;
