import {
  equals,
  find,
  length,
  map,
  mergeDeepLeft,
  mergeLeft,
  prop,
  propEq,
  reject,
  sum,
} from "ramda";
import { generateReducer } from "reduxt";
import actions from "../actions";

const initialState = {
  order: null,
  customer: null,
  status: "completed",
  isRefundProcess: false,
  products: [],
  subtotal: 0,
  tax: 0,
  total: 0,
  shippingMethod: "",
  shippingTotal: 0,
  settings: {
    error: false,
    loaded: false,
    requesting: false,
    item: {},
  },
  coupons: [],
};

const getTotalInfo = (products, shippingTotal, shippingTaxPercent) => {
  const subtotal = sum(map(prop("total"), products));
  const tax = sum([
    shippingTotal * (shippingTaxPercent / 100),
    ...map(prop("totalTax"), products),
  ]);
  const total = subtotal + tax + shippingTotal;

  return {
    subtotal,
    shippingTotal,
    total,
    tax,
  };
};

const getProductTotal = (product) => {
  const productTaxPercent = parseFloat(product.taxPercent) / 100;

  return {
    total: product.price * product.quantity,
    totalTax: product.price * product.quantity * productTaxPercent,
  };
};

const getShippingPrice = (shippingMethod, allShippingMethods) => {
  const shippingMethodInfo = find(
    propEq("instanceId", shippingMethod),
    allShippingMethods
  );

  return (shippingMethodInfo && shippingMethodInfo.price) || 0;
};

const addItemFn = (state, { payload }) => {
  const isPriceWithTax = state.settings.item.taxes.priceWithTax;
  const shippingMethods = state.settings.item.shipping.methods;
  const { shippingTaxPercent } = state.settings.item.taxes;
  const productTaxPercent = parseFloat(payload.taxPercent) / 100;

  let products;
  const productInfo = {
    ...payload,
    price: isPriceWithTax
      ? payload.price / (productTaxPercent + 1)
      : payload.price,
    quantity: 1,
    order: false,
  };

  const newProduct = {
    ...productInfo,
    ...getProductTotal(productInfo),
  };

  if (equals(length(state.products), 0)) {
    products = [newProduct];
  } else if (find(propEq("productId", newProduct.productId), state.products)) {
    products = map((product) => {
      if (product.productId === newProduct.productId) {
        const quantity = product.quantity + 1;

        const updatedProduct = {
          ...newProduct,
          id: product.id,
          quantity,
        };

        return {
          ...updatedProduct,
          ...getProductTotal(updatedProduct),
        };
      }
      return product;
    }, state.products);
  } else {
    products = [...state.products, newProduct];
  }

  return mergeDeepLeft(
    {
      products,
      ...getTotalInfo(
        products,
        getShippingPrice(state.shippingMethod, shippingMethods),
        shippingTaxPercent
      ),
    },
    state
  );
};

const updateItemFn = (state, { payload }) => {
  const shippingMethods = state.settings.item.shipping.methods;
  const { shippingTaxPercent } = state.settings.item.taxes;

  const productInfo = find(
    propEq("productId", payload.productId),
    state.products
  );

  let products;
  if (
    equals(payload.quantity, 0) &&
    productInfo &&
    productInfo.order === false
  ) {
    products = reject(propEq("productId", payload.productId), state.products);
  } else {
    products = map((product) => {
      if (product.productId === payload.productId) {
        const updatedProduct = {
          ...product,
          quantity: payload.quantity,
        };

        return {
          ...updatedProduct,
          ...getProductTotal(updatedProduct),
        };
      }
      return product;
    }, state.products);
  }

  const totalInfo = getTotalInfo(
    products,
    getShippingPrice(state.shippingMethod, shippingMethods),
    shippingTaxPercent
  );

  return mergeDeepLeft(
    {
      products,
      ...totalInfo,
      status: state.order && totalInfo.total === 0 ? "cancelled" : state.status,
    },
    state
  );
};

const removeItemFn = (state, { payload }) => {
  const shippingMethods = state.settings.item.shipping.methods;
  const { shippingTaxPercent } = state.settings.item.taxes;

  const productInfo = find(
    propEq("productId", payload.productId),
    state.products
  );

  const products =
    productInfo && productInfo.order === true
      ? map((product) => {
          if (product.productId === payload.productId) {
            const updatedProduct = {
              ...product,
              quantity: payload.quantity || 0,
            };

            return {
              ...updatedProduct,
              ...getProductTotal(updatedProduct),
            };
          }
          return product;
        }, state.products)
      : reject(propEq("productId", payload.productId), state.products);

  const totalInfo = getTotalInfo(
    products,
    getShippingPrice(state.shippingMethod, shippingMethods),
    shippingTaxPercent
  );

  return mergeDeepLeft(
    {
      products,
      ...totalInfo,
      status: state.order && totalInfo.total === 0 ? "cancelled" : state.status,
    },
    state
  );
};

const assignCustomerFn = (state, { payload }) =>
  mergeDeepLeft(
    {
      customer: payload,
    },
    state
  );

const clearCustomerFn = (state) =>
  mergeDeepLeft(
    {
      customer: null,
    },
    state
  );

const assignOrderFn = (state, { payload }) => {
  const shippingMethods = state.settings.item.shipping.methods;
  const newShippingMethod = payload.order.shippingMethod.instanceId || "";
  const { shippingTaxPercent } = state.settings.item.taxes;

  const products = map((product) => {
    const formattedProduct = {
      id: product.id,
      productId: product.productId,
      name: product.name,
      price: product.price,
      quantity: product.quantity,
      taxPercent:
        (product.taxes &&
          product.taxes.length > 0 &&
          product.taxes[0].percent) ||
        0,
      taxes: product.taxes,
      order: true,
    };

    return {
      ...formattedProduct,
      ...getProductTotal(formattedProduct),
    };
  }, payload.products);

  return mergeDeepLeft(
    {
      customer: payload.customer,
      products,
      isRefundProcess: payload.isRefundProcess,
      order: payload.order,
      ...getTotalInfo(
        products,
        getShippingPrice(newShippingMethod, shippingMethods),
        shippingTaxPercent
      ),
      coupons:
        map(
          (coupon) => ({
            id: coupon.id,
            code: coupon.code,
            order: true,
          }),
          payload.order.coupons
        ) || [],
      shippingMethod: newShippingMethod,
      status: payload.order.status,
    },
    state
  );
};

const clearCartFn = (state) => {
  const settingShipMethod = find(
    propEq("methodId", "local_pickup"),
    state.settings.item.shipping.methods
  );

  return {
    ...initialState,
    settings: state.settings,
    shippingMethod: (settingShipMethod && settingShipMethod.instanceId) || "",
  };
};

const fetchSettingsFn = (state) =>
  mergeLeft(
    {
      settings: mergeLeft(
        {
          error: false,
          loaded: false,
          requesting: true,
        },
        state.settings
      ),
    },
    state
  );

const fetchSettingsSuccessFn = (state, { payload }) => {
  const settingShipMethod = find(
    propEq("methodId", "local_pickup"),
    payload.shipping.methods
  );

  return mergeLeft(
    {
      settings: mergeLeft(
        {
          loaded: true,
          requesting: false,
          item: payload,
        },
        state.settings
      ),
      shippingMethod: (settingShipMethod && settingShipMethod.instanceId) || "",
    },
    state
  );
};

const fetchSettingsErrorFn = (state) =>
  mergeLeft(
    {
      settings: mergeLeft(
        {
          loaded: true,
          error: true,
          requesting: false,
          item: {},
        },
        state.settings
      ),
    },
    state
  );

const changeStatusFn = (state, { payload }) =>
  mergeDeepLeft(
    {
      status: payload,
    },
    state
  );

const changeShippingMethodFn = (state, { payload }) =>
  mergeDeepLeft(
    {
      shippingMethod: payload,
      ...getTotalInfo(
        state.products,
        getShippingPrice(payload, state.settings.item.shipping.methods),
        state.settings.item.taxes.shippingTaxPercent
      ),
    },
    state
  );

const addCouponFn = (state, { payload }) => {
  const newCoupon = {
    ...payload,
    order: false,
  };

  let coupons;
  if (equals(length(state.coupons), 0)) {
    coupons = [newCoupon];
  } else if (find(propEq("code", payload.code), state.coupons)) {
    coupons = state.coupons;
  } else {
    coupons = [...state.coupons, newCoupon];
  }

  return mergeDeepLeft(
    {
      coupons,
    },
    state
  );
};

const removeCouponFn = (state, { payload }) =>
  mergeDeepLeft(
    {
      coupons: reject(propEq("code", payload), state.coupons),
    },
    state
  );

const reducer = generateReducer(initialState, {
  [actions.types.cart.addItem]: addItemFn,
  [actions.types.cart.updateItem]: updateItemFn,
  [actions.types.cart.removeItem]: removeItemFn,
  [actions.types.cart.clearCart]: clearCartFn,
  [actions.types.cart.assignCustomer]: assignCustomerFn,
  [actions.types.cart.clearCustomer]: clearCustomerFn,
  [actions.types.cart.assignOrder]: assignOrderFn,
  [actions.types.cart.fetchSettings]: fetchSettingsFn,
  [actions.types.cart.fetchSettingsSuccess]: fetchSettingsSuccessFn,
  [actions.types.cart.fetchSettingsError]: fetchSettingsErrorFn,
  [actions.types.cart.changeStatus]: changeStatusFn,
  [actions.types.cart.changeShippingMethod]: changeShippingMethodFn,
  [actions.types.cart.addCoupon]: addCouponFn,
  [actions.types.cart.removeCoupon]: removeCouponFn,
});

export default reducer;
