import PropTypes from "prop-types";
import React from "react";

import globalProps from "../../../props";

import { useFormControl } from "../FormControl";
import { SInput, SIcon, SWrapper } from "./styles";

/**
 *
 * Input is a basic component that using the basic markup 'input'.
 *
 * It uses FormContext to connect the form context.
 */
const Input = ({
  isDisabled,
  icon,
  onIconClick,
  size,
  className,
  ...props
}) => {
  const updatedProps = useFormControl({ isDisabled, ...props });

  const IconComponent = icon ? (
    <SIcon
      size={size}
      onClick={isDisabled ? undefined : onIconClick}
      isDisabled={isDisabled}
    >
      {icon}
    </SIcon>
  ) : null;

  return (
    <SWrapper className={className}>
      <SInput size={size} {...updatedProps} disabled={isDisabled} />
      {IconComponent}
    </SWrapper>
  );
};

Input.defaultProps = {
  ...globalProps.styledComponents.className.defaultProps,
  icon: undefined,
  isInvalid: undefined,
  isRequired: undefined,
  isDisabled: undefined,
  isReadOnly: undefined,
  as: "input",
  value: undefined,
  onChange: undefined,
  onKeyDown: undefined,
  onIconClick: undefined,
  size: "default",
};

Input.propTypes = {
  ...globalProps.styledComponents.className.types,
  icon: PropTypes.node,
  isInvalid: PropTypes.bool,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  name: PropTypes.string.isRequired,
  as: PropTypes.oneOf(["input", "textarea"]),
  value: PropTypes.string,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  onIconClick: PropTypes.func,
  size: PropTypes.oneOf(["default", "sm"]),
};

export default Input;
