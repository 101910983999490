import PropTypes from "prop-types";
import React from "react";
import { HomeIcon } from "#components";
import {
  ContentWrapper,
  FooterWrapper,
  HeaderContentWrapper,
  HeaderWrapper,
  StyledIcon,
  StyledLink,
  Wrapper,
} from "./styles";

const MainLayout = ({
  children,
  header,
  headerIcon,
  headerIconLink,
  footer,
}) => {
  return (
    <Wrapper>
      {header && (
        <HeaderWrapper>
          <StyledLink to={headerIconLink}>
            <StyledIcon as={headerIcon} />
          </StyledLink>
          <HeaderContentWrapper>{header}</HeaderContentWrapper>
        </HeaderWrapper>
      )}
      <ContentWrapper>{children}</ContentWrapper>
      {footer && <FooterWrapper>{footer}</FooterWrapper>}
    </Wrapper>
  );
};

MainLayout.displayName = "MainLayout";

MainLayout.defaultProps = {
  header: undefined,
  headerIcon: HomeIcon,
  headerIconLink: "/dashboard",
  footer: undefined,
};

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.node,
  headerIcon: PropTypes.elementType,
  headerIconLink: PropTypes.string,
  footer: PropTypes.node,
};

export default MainLayout;
