const colors = {
  bg: "#FFFFFF",
  text: "#222222",
  fg: "#FAFAFA",
  buttonText: "#FFFFFF",
  hover: "#EBECED",
  primary: "#2891D0",
  secondary: "#8E8E8E",
  border: "#C8C8C8",
  ok: "#00C853",
  ko: "#FF3B3B",
  warning: "#F89401",
};

export default colors;
