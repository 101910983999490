import styled from "@xstyled/styled-components";
import {
  alignItems,
  fontSize,
  fontWeight,
  minWidth,
  paddingLeft,
  textAlign,
} from "@xstyled/system";

export const Wrapper = styled.div`
  width: 300px;
  padding: sm;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${alignItems}
`;

export const StyledImage = styled.img`
  margin-bottom: sm;
  width: 100px;
  height: 50px;
`;

export const CompanyName = styled.h2`
  font-size: 1.5em;
`;

export const ReceiptAddressInfo = styled.span`
  font-size: 1em;
  color: #000;
  line-height: 1.2em;
`;

export const ReceiptInfo = styled.span`
  font-size: 0.8em;
  text-align: center;
  display: block;
  margin: 10px 0;
  ${fontSize};
`;

export const TableWrapper = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin: 10px 0;
`;

export const TableRowHead = styled.tr`
  font-size: 0.8em;

  border-bottom-width: default;
  border-bottom-color: text;
  border-bottom-style: solid;
`;

export const TableHead = styled.th`
  padding: 0 0 xs;
`;

export const TableRowBody = styled.tr`
  border-bottom-width: default;
  border-bottom-color: text;
  border-bottom-style: dotted;
`;

export const TableData = styled.td`
  font-size: 0.8em;
  padding: xs 0;
  ${textAlign};
  ${minWidth};
`;

export const TotalWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TotalText = styled.span`
  font-size: 1em;
  font-weight: bold;
  padding: 0 xs;
`;

export const CustomerAddressInfo = styled.span`
  font-size: 0.8em;
  ${paddingLeft};
  ${textAlign};
  ${fontWeight};
`;

export const TaxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-width: default;
  border-color: text;
  border-style: dotted;
  border-radius: card;
  min-width: 50%;
  width: 50%;
  margin-left: auto;
  padding: xs 0;
  margin-top: xs;
`;

export const TaxItem = styled.div`
  display: flex;
  justify-content: space-around;
  &:nth-child(2n) {
    padding: xxs 0;
  }
`;

export const TaxText = styled.span`
  font-size: 0.7em;
  padding: 0 xs;
`;
