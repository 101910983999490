import React from "react";
import { Redirect, Route, Switch, useParams } from "react-router-dom";
import CustomerInfo from "./CustomerInfo";
import CustomerOrders from "./CustomerOrders";

const EditCustomer = () => {
  const { customerId } = useParams();

  if (/^\d+$/.test(customerId) === false) {
    return <Redirect to="/customers" />;
  }

  return (
    <Switch>
      <Route path="/customer/:customerId/info" component={CustomerInfo} />
      <Route path="/customer/:customerId/orders" component={CustomerOrders} />
      <Redirect to={`/customer/${customerId}/info`} />
    </Switch>
  );
};

EditCustomer.displayName = "EditCustomer";

export default EditCustomer;
