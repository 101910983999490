import PropTypes from "prop-types";
import { map, path } from "ramda";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { actions } from "#store";
import { apiService } from "#services";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  Button,
  Icon,
  LoadingIcon,
  MainLayout,
  RefreshIcon,
  Searcher,
} from "#components";
import ProductCard from "./ProductCard";
import { LoadingWrapper, ProductsWrapper } from "./styles";

const ListProductsHeader = ({
  searchFunc,
  pageFunc,
  relaunch,
  relaunchFunc,
}) => {
  const [product, setProduct] = useState("");

  return (
    <>
      <Searcher
        name="customerSearcher"
        placeholder="Ingrese el nombre del producto"
        size="sm"
        value={product}
        onChange={setProduct}
        onClick={() => {
          pageFunc(1);
          searchFunc(product);
        }}
      />
      <Button
        variant="link"
        size="sm"
        shape="square"
        icon={RefreshIcon}
        iconColor="text"
        onClick={() => relaunchFunc(!relaunch)}
      />
    </>
  );
};

const ListProductsFooter = ({ page, pageFunc }) => {
  return (
    <>
      <div />
      <div>
        {page !== 1 && (
          <Button
            variant="link"
            size="sm"
            shape="square"
            icon={ArrowLeftIcon}
            onClick={() => pageFunc(page - 1)}
          />
        )}
        <Button
          variant="link"
          size="sm"
          shape="square"
          icon={ArrowRightIcon}
          onClick={() => pageFunc(page + 1)}
        />
      </div>
    </>
  );
};

const ListProducts = ({
  searchFunc,
  page,
  pageFunc,
  relaunch,
  relaunchFunc,
}) => {
  const isLoaded = useSelector(path(["product", "all", "loaded"]));
  const isError = useSelector(path(["product", "all", "error"]));
  const isLoading = useSelector(path(["product", "all", "requesting"]));
  const products = useSelector(path(["product", "all", "items"]));

  if (isError && isLoaded) {
    return <Redirect to="/" />;
  }

  const HeaderLayout = (
    <ListProductsHeader
      searchFunc={searchFunc}
      pageFunc={pageFunc}
      relaunch={relaunch}
      relaunchFunc={relaunchFunc}
    />
  );

  return (
    <MainLayout
      header={HeaderLayout}
      footer={<ListProductsFooter page={page} pageFunc={pageFunc} />}
    >
      <ProductsWrapper>
        {!isLoaded || (isLoading && !isLoaded) ? (
          <LoadingWrapper>
            <Icon
              IconComponent={LoadingIcon}
              stroke="border.default"
              width="100px"
              height="100px"
            />
          </LoadingWrapper>
        ) : (
          <>
            {map(
              (product) => (
                <ProductCard
                  key={`productList${product.id}`}
                  id={product.id}
                  name={product.name}
                  image={product.image}
                  price={product.price}
                  taxPercent={product.taxPercent}
                  stock={product.stockQuantity}
                  initialStock={product.initialStockQuantity}
                />
              ),
              products
            )}
          </>
        )}
      </ProductsWrapper>
    </MainLayout>
  );
};

const ListProductsContainer = () => {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [relaunch, setRelaunch] = useState(false);

  useEffect(() => {
    apiService.product.fetchAll({
      actionsCreator: {
        start: () => ({ type: actions.types.product.fetchAll }),
        success: (payload) => ({
          type: actions.types.product.fetchAllSuccess,
          payload,
        }),
        error: () => ({ type: actions.types.product.fetchAllError }),
      },
      urlParameters: {
        search,
        page,
      },
      notifications: {
        error: true,
      },
    });
  }, [relaunch, search, page]);

  return (
    <ListProducts
      searchFunc={setSearch}
      page={page}
      pageFunc={setPage}
      relaunch={relaunch}
      relaunchFunc={setRelaunch}
    />
  );
};

ListProductsHeader.displayName = "ListProductsHeader";
ListProductsHeader.propTypes = {
  searchFunc: PropTypes.func.isRequired,
  pageFunc: PropTypes.func.isRequired,
  relaunch: PropTypes.bool.isRequired,
  relaunchFunc: PropTypes.func.isRequired,
};

ListProductsFooter.displayName = "ListProductsFooter";
ListProductsFooter.propTypes = {
  page: PropTypes.number.isRequired,
  pageFunc: PropTypes.func.isRequired,
};

ListProducts.displayName = "ListProducts";
ListProducts.propTypes = {
  searchFunc: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  pageFunc: PropTypes.func.isRequired,
  relaunch: PropTypes.bool.isRequired,
  relaunchFunc: PropTypes.func.isRequired,
};

ListProductsContainer.displayName = "ListProductsContainer";

export default ListProductsContainer;
