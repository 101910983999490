import React, { useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Header, OrderComponent, OrderHeader, Sidebar } from "#components";
import { APP_NAME } from "#constants";
import CustomReceiptProducts from "./CustomReceiptProducts";
import Dashboard from "./Dashboard";
import EditCustomer from "./EditCustomer";
import ListCoupons from "./ListCoupons";
import ListCustomers from "./ListCustomers";
import ListOrders from "./ListOrders";
import ListProducts from "./ListProducts";
import Logout from "./Logout";
import {
  ContentWrapper,
  OrderWrapper,
  SidebarWrapper,
  SiteTitle,
  Wrapper,
} from "./styles";
import ViewOrder from "./ViewOrder";

const Logged = () => {
  const [sidebarHide, setSidebarHide] = useState();
  return (
    <Wrapper>
      <SidebarWrapper display={sidebarHide ? "none" : null}>
        <SiteTitle>{APP_NAME}</SiteTitle>
        <Sidebar sidebarHide={sidebarHide} />
      </SidebarWrapper>
      <ContentWrapper>
        <Header sidebarHide={sidebarHide} setSidebarHide={setSidebarHide} />

        <Switch>
          <Route path="/dashboard" component={Dashboard} exact />
          <Route path="/products" component={ListProducts} exact />
          <Route path="/orders" component={ListOrders} exact />
          <Route
            path="/order/:orderId/custom-receipt"
            component={CustomReceiptProducts}
          />
          <Route path="/order/:orderId" component={ViewOrder} />
          <Route path="/coupons" component={ListCoupons} exact />
          <Route path="/customers" component={ListCustomers} exact />
          <Route path="/customer/:customerId" component={EditCustomer} />
          <Route path="/logout" component={Logout} exact />
          <Redirect to="/" />
        </Switch>
      </ContentWrapper>
      <OrderWrapper>
        <OrderHeader />
        <OrderComponent />
      </OrderWrapper>
    </Wrapper>
  );
};

Logged.displayName = "Logged";

export default Logged;
