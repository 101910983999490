import PropTypes from "prop-types";
import React, { Children, cloneElement, useState } from "react";
import props from "../../../props";
import { SGroupWrapper } from "./styles";

/**
 *
 * RadioButtonGroup component.
 *
 * Can be controlled (parent component controls its value)
 * or uncontrolled (it controls its value on its own).
 *
 * If controlled: "value" prop is required. "onClick" prop is optional for handling.
 * If uncontrolled: "onChange" prop is optional for handling. "initialValue" is optional for initial check status.
 */
const RadioButtonGroup = ({
  value,
  initialValue,
  onClick,
  onChange,
  children,
}) => {
  // Controlled/uncontrolled component
  const isControlled = value !== undefined;

  // Value state in case of uncontrolled component
  const [currentValue, setCurrentValue] = useState(initialValue);

  // Radio items list to be rendered
  const items = isControlled
    ? Children.toArray(children).map((child) =>
        cloneElement(child, {
          checked: value === child.props.value,
          onClick,
        })
      )
    : Children.toArray(children).map((child) =>
        cloneElement(child, {
          checked: currentValue === child.props.value,
          onClick: () => {
            setCurrentValue(child.props.value);
            if (onChange) {
              onChange(child.props.value);
            }
          },
        })
      );

  return <SGroupWrapper>{items}</SGroupWrapper>;
};

RadioButtonGroup.defaultProps = {
  ...props.styledComponents.className.defaultProps,
  value: undefined,
  initialValue: undefined,
  onClick: undefined,
  onChange: undefined,
};

RadioButtonGroup.propTypes = {
  ...props.styledComponents.className.types,
  value: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
  initialValue: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  children: PropTypes.node.isRequired,
};

export default RadioButtonGroup;
