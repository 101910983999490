// import { Button, CheckBox, FormControl } from "@sewan/ui-kit";
import { equals, find, length, map, propEq } from "ramda";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  CheckBox,
  FormControl,
  FormGenerator,
  Notifications,
} from "#components";
import { APP_NAME } from "#constants";
import { apiService } from "#services";
import { actions } from "#store";
import { LoginWrapper, NotificationWrapper, Wrapper } from "./styles";

const Login = () => {
  const history = useHistory();
  const [formData, setFormData] = useState([
    {
      component: "input",
      type: "text",
      name: "username",
      label: "Usuario",
      value: "",
      error: false,
    },
    {
      component: "input",
      type: "password",
      name: "password",
      label: "Contraseña",
      value: "",
      error: false,
    },
  ]);

  const [stayLogged, setStayLogged] = useState(false);

  const handlerSubmit = () => {
    const newFieldsData = map(
      (fieldData) => ({
        ...fieldData,
        error: equals(length(fieldData.value), 0),
      }),
      formData
    );
    if (find(propEq("error", true), newFieldsData)) {
      setFormData(newFieldsData);
      return;
    }

    const getParameter = (name) => find(propEq("name", name), formData);

    apiService.auth.authenticate({
      actionsCreator: {
        start: () => ({ type: actions.types.auth.authenticate }),
        success: (payload) => {
          localStorage.setItem("isLoggedIn", true);
          if (payload && payload.token) {
            localStorage.setItem("token", payload.token);
          }
          if (payload && payload.name) {
            localStorage.setItem("name", payload.name);
          }
          history.push("/");
          return {
            type: actions.types.auth.authenticateSuccess,
            payload,
          };
        },
        error: () => {
          return { type: actions.types.auth.authenticateError };
        },
      },
      parameters: {
        username: getParameter("username").value,
        password: getParameter("password").value,
        stayIn: stayLogged,
      },
      notifications: {
        error: true,
        success: true,
      },
    });
  };

  return (
    <Wrapper>
      <NotificationWrapper>
        <Notifications />
      </NotificationWrapper>
      <LoginWrapper>
        <FormGenerator
          formData={formData}
          handlerFormData={setFormData}
          title={APP_NAME}
        >
          <FormControl>
            <CheckBox
              name="stayLogged"
              label="Mantener sesión iniciada"
              checked={stayLogged}
              onClick={() => setStayLogged(!stayLogged)}
            />
          </FormControl>
          <Button
            width="block"
            label="Iniciar sesión"
            onClick={handlerSubmit}
          />
        </FormGenerator>
      </LoginWrapper>
    </Wrapper>
  );
};

Login.displayName = "Login";

export default Login;
