import PropTypes from "prop-types";

const className = {
  types: {
    className: PropTypes.string,
  },
  defaultProps: {
    className: undefined,
  },
};

export default {
  className,
};
