import PropTypes from "prop-types";
import React from "react";
import { useDispatch } from "react-redux";
import { actions } from "#store";
import {
  ProductImage,
  ProductImageWrapper,
  ProductInfo,
  ProductName,
  ProductPrice,
  ProductStock,
  ProductWrapper,
} from "./styles";

const ProductCard = ({
  id,
  name,
  image,
  price,
  taxPercent,
  stock,
  initialStock,
}) => {
  const dispatch = useDispatch();

  const handlerAddProductToCart = () => {
    dispatch(
      actions.creators.cart.addItem({
        productId: id,
        name,
        price: parseFloat(price),
        taxPercent,
      })
    );
  };

  return (
    <ProductWrapper onClick={handlerAddProductToCart}>
      <ProductImageWrapper>
        {image && <ProductImage src={image} />}
      </ProductImageWrapper>
      {(name || price || stock) && (
        <ProductInfo>
          <ProductName>{name}</ProductName>
          <ProductPrice>{parseFloat(price).toFixed(2)} €</ProductPrice>
          {stock && (
            <ProductStock>
              {stock} {initialStock && <> / {initialStock}</>}
            </ProductStock>
          )}
        </ProductInfo>
      )}
    </ProductWrapper>
  );
};

ProductCard.displayName = "ProductCard";

ProductCard.defaultProps = {
  image: "",
  stock: undefined,
  initialStock: undefined,
  taxPercent: undefined,
};

ProductCard.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  name: PropTypes.string.isRequired,
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  taxPercent: PropTypes.number,
  image: PropTypes.string,
  stock: PropTypes.number,
  initialStock: PropTypes.number,
};

export default ProductCard;
