import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import globalProps from "../../../props";
import { useFormControl } from "../FormControl";
import {
  SContent,
  SInnerCircle,
  SLabel,
  SMask,
  SRadioButton,
  SRealInput,
  SText,
  SWrapper,
} from "./styles";

/**
 * Can be controlled (parent component controls its value)
 * or uncontrolled (it controls its value on its own).
 *
 * If controlled: "checked" prop is required. "onClick" prop is optional for handling.
 * If uncontrolled: "onChange" prop is optional for handling. "initialCheck" is optional for initial check status.
 */
const RadioButton = ({
  label,
  checked,
  onChange,
  onClick,
  initialCheck,
  value,
  className,
  ...props
}) => {
  // Controlled/uncontrolled component
  const isControlled = checked !== undefined;

  // FormControl flags
  const { isDisabled } = useFormControl(props);

  // Checked state is internally handled only in case of uncontrolled component
  const [isCheckedState, setIsCheckedState] = useState(initialCheck);
  const isChecked = isControlled ? checked : isCheckedState;
  const toggleIsChecked = useCallback(() => {
    if (!isDisabled) {
      if (isControlled) {
        if (onClick) {
          onClick(value);
        }
      } else {
        setIsCheckedState(!isChecked);
        if (onChange) {
          onChange(!isChecked);
        }
      }
    }
  }, [
    isControlled,
    setIsCheckedState,
    isChecked,
    onChange,
    onClick,
    value,
    isDisabled,
  ]);

  // Hover state is always internally handled
  const [isHover, setIsHover] = useState(false);
  const toggleIsHover = useCallback(() => setIsHover((state) => !state), [
    setIsHover,
  ]);

  return (
    <SWrapper className={className}>
      <SContent
        onMouseEnter={toggleIsHover}
        onMouseLeave={toggleIsHover}
        isDisabled={isDisabled}
      >
        <SLabel onClick={toggleIsChecked}>
          <SRealInput
            type="radio"
            checked={isChecked}
            data-testid="radiobutton"
          />
          <SRadioButton
            isChecked={isChecked}
            isHover={isHover}
            isDisabled={isDisabled}
          >
            {isChecked && <SInnerCircle data-testid="checkIcon" />}
          </SRadioButton>
          <SText isChecked={isChecked}>{label}</SText>
        </SLabel>
      </SContent>
      <SMask isDisabled={isDisabled} />
    </SWrapper>
  );
};

RadioButton.defaultProps = {
  ...globalProps.styledComponents.className.defaultProps,
  isInvalid: undefined,
  isRequired: undefined,
  isDisabled: undefined,
  isReadOnly: undefined,
  label: "",
  value: "",
  initialCheck: false,
  checked: undefined,
  onChange: undefined,
  onClick: undefined,
};

RadioButton.propTypes = {
  ...globalProps.styledComponents.className.types,
  isInvalid: PropTypes.bool,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  initialCheck: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
};

export default RadioButton;
