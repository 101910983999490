import styled from "@xstyled/styled-components";

export const CustomerOrdersWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: space-evenly;
  width: 100%;
  height: calc(100vh - 173px);
  overflow: auto;
`;

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 173px);
  background-color: bg.L9;
  border-radius: card;
`;
