import styled from "@xstyled/styled-components";
import { NavLink } from "react-router-dom";
import { Icon } from "#components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100%;
`;

export const StyledLink = styled(NavLink)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: bg.D8;
  width: 100%;
  height: 100%;

  text-decoration: none;
  color: text.L9;
  padding: xs;

  border-style: solid;
  border-color: border.D3;
  border-top-width: default;
  border-bottom-width: default;
  border-right-width: bold;
  border-left-width: bold;

  &:hover {
    background-color: bg.D9;
  }

  &.active {
    background-color: bg.D7;
  }
`;

export const StyledIcon = styled(Icon)`
  height: 40px;
  width: 40px;
`;

export const StyledText = styled.span`
  padding-top: xs;
`;

export const LogoutLink = styled(StyledLink)`
  background-color: ko.D2;
  min-height: 70px;
  height: 70px;

  &:hover {
    background-color: ko.L1;
  }
`;
