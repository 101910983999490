import styled, { css } from "@xstyled/styled-components";

export const SWrapper = styled.div`
  color: ko;
  margin: xxs 0;
  font-size: xs;

  background: transparent;

  ${({ isDisabled }) =>
    css({
      opacity: isDisabled ? "0.5" : "1",
    })};
`;
