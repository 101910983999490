import styled from "@xstyled/styled-components";
import { Button } from "#components";

export const OrdersWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
  height: calc(100vh - 173px);
  overflow: auto;
`;

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 173px);
  background-color: bg.L9;
  border-radius: card;
`;

export const StyledStoreButton = styled(Button)`
  color: primary.D3;
  text-decoration: none;
  padding: xs;
  border-style: solid;
  border-width: bold;
  border-color: primary.D2;
  color: primary.D2;
  margin: xxs;

  &.active {
    border-color: primary.L1;
    color: primary.L1;
  }
`;
