import styled, { css } from "@xstyled/styled-components";
import { variant } from "@xstyled/system";
import { Button } from "reakit";

const shapeCommunStyles = css`
  padding: 0;
  align-items: center;
  justify-content: center;

  svg {
    margin-right: 0px;
  }
`;

export const SIcon = styled.svg`
  ${({ iconColor }) => css({ fill: iconColor })};
  ${({ iconSize }) =>
    css({
      height: iconSize,
      width: iconSize,
    })};
`;

const VARIANT_SIZE_THEME_KEY = {
  default: "form",
  sm: "formSM",
};

const widthVariant = variant({
  // Button width /default and block verisons
  default: "default",
  prop: "width",
  variants: {
    default: css`
      width: auto;
    `,
    block: css`
      width: 100%;
      justify-content: center;
    `,
  },
});

export const SButton = styled(Button)`
  border: none;
  box-sizing: border-box;
  cursor: pointer;
  // display: inline;
  align-items: center;
  font-weight: bold;
  font-style: normal;
  font-weight: normal;
  outline: none;
  line-height: 1;
  user-select: none;

  border-radius: default;
  color: buttonText;
  font-size: default;
  transition: default;

  &:active {
    outline: none;
    box-shadow: none;
    transition: fast;
  }

  ${variant({
    // Main Button Variations
    default: "primary",
    prop: "variant",
    variants: {
      primary: css`
        background-color: primary;
        &:hover {
          background-color: primary.D1;
        }
        &:active {
          background-color: primary.D2;
        }
      `,
      ghost: css`
        border-style: solid;
        background-color: secondary.L4;

        border-width: default;
        border-color: border;
        color: secondary.D3;
        &:hover {
          background-color: secondary.L3;
        }
        &:active {
          background-color: secondary.L2;
        }
      `,
      link: css`
        background-color: transparent;
        color: primary;

        &:hover {
          text-decoration: underline;
          box-shadow: none;
          color: primary.D1;
        }
      `,
      secondary: css`
        background-color: secondary;
        &:hover {
          background-color: secondary.D1;
        }
        &:active {
          background-color: secondary.D2;
        }
      `,
      ok: css`
        background-color: ok;
        &:hover {
          background-color: ok.D1;
        }
        &:active {
          background-color: ok.D2;
        }
      `,
      ko: css`
        background-color: ko;
        &:hover {
          background-color: ko.D1;
        }
        &:active {
          background-color: ko.D2;
        }
      `,
    },
  })}

  ${variant({
    // Button sizes
    default: "default",
    prop: "size",
    variants: {
      default: css`
        height: form;

        & svg {
          margin-right: xs;
        }
      `,
      sm: css`
        height: formSM;
        font-size: md;
        padding: 0 xs;

        & svg {
          margin-right: xxs;
        }
      `,
      lg: css`
        height: lg;

        & svg {
          margin-right: xxs;
        }
      `,
      xl: css`
        height: xl;

        & svg {
          margin-right: xxs;
        }
      `,
    },
  })}

    ${widthVariant}

    ${variant({
    // Shape Button variations
    prop: "shape",
    default: "default",
    variants: {
      default: css`
        padding: 0 sm;
      `,
      circle: css`
        border-radius: 100%;
        ${shapeCommunStyles}
      `,
      square: css`
        ${shapeCommunStyles}
      `,
    },
  })};

  ${({ disabled }) =>
    css({
      opacity: disabled ? "0.5" : "1",
      cursor: disabled ? "not-allowed" : "pointer",
    })};

  ${({ size, shape }) =>
    shape &&
    shape !== "default" &&
    size &&
    css({
      height: VARIANT_SIZE_THEME_KEY[size] || size,
      width: VARIANT_SIZE_THEME_KEY[size] || size,
    })};
`;

export const SWrapper = styled.div`
  display: inline;
  margin: xxs;
  border-radius: default;

  ${widthVariant}
  ${({ shape }) => shape === "circle" && css({ borderRadius: "50%" })}
`;

// Button Group
export const ButtonGroup = styled.div`
  display: inline-flex;

  * {
    border-radius: 0;
    margin: 0;
  }

  & > *:first-of-type,
  *:first-of-type > button {
    border-radius: default;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    margin-right: 0;
  }

  & > *:last-of-type,
  *:last-of-type > button {
    border-radius: default;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-left: 0;
  }
`;
