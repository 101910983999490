import PropTypes from "prop-types";
import { map, path } from "ramda";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { actions } from "#store";
import { apiService } from "#services";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  Button,
  Icon,
  LoadingIcon,
  MainLayout,
  Searcher,
} from "#components";
import CustomerCard from "./CustomerCard";
import { CustomersWrapper, LoadingWrapper } from "./styles";

const ListCustomersHeader = ({ searchFunc, pageFunc }) => {
  const [customer, setCustomer] = useState("");

  return (
    <Searcher
      name="customerSearcher"
      placeholder="Ingrese el nombre del cliente, correo electrónico, teléfono"
      size="sm"
      value={customer}
      onChange={setCustomer}
      onClick={() => {
        pageFunc(1);
        searchFunc(customer);
      }}
    />
  );
};

const ListCustomersFooter = ({ page, pageFunc }) => {
  return (
    <>
      <div />
      <div>
        {page !== 1 && (
          <Button
            variant="link"
            size="sm"
            shape="square"
            icon={ArrowLeftIcon}
            onClick={() => pageFunc(page - 1)}
          />
        )}
        <Button
          variant="link"
          size="sm"
          shape="square"
          icon={ArrowRightIcon}
          onClick={() => pageFunc(page + 1)}
        />
      </div>
    </>
  );
};

const ListCustomers = ({ searchFunc, page, pageFunc }) => {
  const isLoaded = useSelector(path(["customer", "all", "loaded"]));
  const isError = useSelector(path(["customer", "all", "error"]));
  const isLoading = useSelector(path(["customer", "all", "requesting"]));
  const customers = useSelector(path(["customer", "all", "items"]));

  if (isError && isLoaded) {
    return <Redirect to="/" />;
  }

  return (
    <MainLayout
      header={
        <ListCustomersHeader searchFunc={searchFunc} pageFunc={pageFunc} />
      }
      footer={<ListCustomersFooter page={page} pageFunc={pageFunc} />}
    >
      {!isLoaded || (isLoading && !isLoaded) ? (
        <LoadingWrapper>
          <Icon
            IconComponent={LoadingIcon}
            stroke="border.default"
            width="100px"
            height="100px"
          />
        </LoadingWrapper>
      ) : (
        <CustomersWrapper>
          {map(
            (customer) => (
              <CustomerCard
                key={`customerList${customer.id}`}
                id={customer.id}
                customer={`${customer.firstName} ${customer.lastName}`}
                phone={customer.billing.phone}
                email={customer.billing.email}
              />
            ),
            customers
          )}
        </CustomersWrapper>
      )}
    </MainLayout>
  );
};

const ListCustomersContainer = () => {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);

  useEffect(() => {
    apiService.customer.fetchAll({
      actionsCreator: {
        start: () => ({ type: actions.types.customer.fetchAll }),
        success: (payload) => ({
          type: actions.types.customer.fetchAllSuccess,
          payload,
        }),
        error: () => ({ type: actions.types.customer.fetchAllError }),
      },
      urlParameters: {
        search,
        page,
      },
      notifications: {
        error: true,
      },
    });
  }, [search, page]);

  return (
    <ListCustomers searchFunc={setSearch} page={page} pageFunc={setPage} />
  );
};

ListCustomersHeader.displayName = "ListOrdersHeader";
ListCustomersHeader.propTypes = {
  searchFunc: PropTypes.func.isRequired,
  pageFunc: PropTypes.func.isRequired,
};

ListCustomersFooter.displayName = "ListCustomersFooter";
ListCustomersFooter.propTypes = {
  page: PropTypes.number.isRequired,
  pageFunc: PropTypes.func.isRequired,
};

ListCustomers.displayName = "ListCustomers";
ListCustomers.propTypes = {
  searchFunc: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  pageFunc: PropTypes.func.isRequired,
};

ListCustomersContainer.displayName = "ListCustomersContainer";

export default ListCustomersContainer;
