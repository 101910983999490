import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import immutableStateInvariantMiddleware from "redux-immutable-state-invariant";
import thunkMiddleware from "redux-thunk";
import reducers from "./reducers";

export default createStore(
  reducers,
  composeWithDevTools(
    applyMiddleware(thunkMiddleware, immutableStateInvariantMiddleware())
  )
);
