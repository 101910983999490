import PropTypes from "prop-types";
import { withProps } from "recompose";
import { notifications } from "#constants";
import { StyledNotification } from "./styles";

const Notification = withProps(({ message }) => ({
  children: message,
}))(StyledNotification);

Notification.displayName = "Notification";

Notification.propTypes = {
  type: PropTypes.oneOf(Object.values(notifications.types)),
  message: PropTypes.string.isRequired,
};

export default Notification;
