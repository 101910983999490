import styled from "@xstyled/styled-components";
import { display } from "@xstyled/system";
import { Info } from "styled-icons/material";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 103px);
  padding: xs;
  background-color: bg;
  border-radius: card;
  overflow: auto;
`;

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: auto;
  ${display};
`;

export const ProductWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const ReceiptWrapper = styled.div`
  width: 300px;
  min-width: 300px;
  border-color: border;
  border-width: default;
  border-style: solid;
  user-select: none;
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 400px;
  max-width: 800px;
  width: 100%;
  background-color: primary.L4;
  padding: xs;
  margin-bottom: xs;
  border-radius: card;
  font-size: md;
`;

export const InfoIcon = styled(Info)`
  width: 20px;
  height: 20px;
  margin-right: xxs;
  color: primary;
`;
