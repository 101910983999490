import PropTypes from "prop-types";
import React from "react";
import { useFullScreen } from "react-browser-hooks";
import { APP_NAME } from "#constants";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  Button,
  FullscreenExitIcon,
  FullscreenIcon,
  Notifications,
} from "#components";
import { ContentWrapper, SiteTitle, Wrapper } from "./styles";

export const Header = ({ sidebarHide, setSidebarHide }) => {
  const fs = useFullScreen();

  return (
    <Wrapper>
      {sidebarHide && <SiteTitle>{APP_NAME}</SiteTitle>}
      <ContentWrapper>
        <Button
          variant="ghost"
          size="sm"
          shape="square"
          icon={sidebarHide ? ArrowRightIcon : ArrowLeftIcon}
          onClick={() => setSidebarHide(!sidebarHide)}
        />

        <Button
          variant="ghost"
          size="sm"
          shape="square"
          icon={fs.fullScreen ? FullscreenExitIcon : FullscreenIcon}
          onClick={fs.toggle}
        />
      </ContentWrapper>
      <Notifications />
    </Wrapper>
  );
};

Header.displayName = "Header";

Header.defaultProps = {
  sidebarHide: false,
  setSidebarHide: undefined,
};

Header.propTypes = {
  sidebarHide: PropTypes.bool,
  setSidebarHide: PropTypes.func,
};

export default Header;
