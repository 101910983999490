import moment from "moment";
import PropTypes from "prop-types";
import { map, path } from "ramda";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { actions } from "#store";
import { apiService } from "#services";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  Button,
  Icon,
  LoadingIcon,
  MainLayout,
  OrderCard,
  RefreshIcon,
  Searcher,
} from "#components";
import { LoadingWrapper, OrdersWrapper, StyledStoreButton } from "./styles";

const ListOrdersHeader = ({ searchFunc, pageFunc, relaunch, relaunchFunc }) => {
  const [order, setOrder] = useState("");

  return (
    <>
      <Searcher
        name="orderSearcher"
        placeholder="Ingrese el número de pedido"
        size="sm"
        value={order}
        onChange={setOrder}
        onClick={() => {
          pageFunc(1);
          searchFunc(order);
        }}
      />
      <Button
        variant="link"
        size="sm"
        shape="square"
        icon={RefreshIcon}
        iconColor="text"
        onClick={() => relaunchFunc(!relaunch)}
      />
    </>
  );
};

const ListOrdersFooter = ({ page, pageFunc, storeType, storeTypeFunc }) => {
  return (
    <>
      <div>
        <StyledStoreButton
          variant="link"
          shape="square"
          label="Pedidos Online"
          onClick={() => {
            pageFunc(1);
            storeTypeFunc("Online");
          }}
          className={storeType === "Online" ? "active" : ""}
        />
        <StyledStoreButton
          variant="link"
          shape="square"
          label="Pedidos TPV"
          onClick={() => {
            pageFunc(1);
            storeTypeFunc("TPV");
          }}
          className={storeType === "TPV" ? "active" : ""}
        />
      </div>
      <div>
        {page !== 1 && (
          <Button
            variant="link"
            size="sm"
            shape="square"
            icon={ArrowLeftIcon}
            onClick={() => pageFunc(page - 1)}
          />
        )}
        <Button
          variant="link"
          size="sm"
          shape="square"
          icon={ArrowRightIcon}
          onClick={() => pageFunc(page + 1)}
        />
      </div>
    </>
  );
};

const ListOrders = ({
  searchFunc,
  page,
  pageFunc,
  storeType,
  storeTypeFunc,
  relaunch,
  relaunchFunc,
}) => {
  const isLoaded = useSelector(path(["order", "all", "loaded"]));
  const isError = useSelector(path(["order", "all", "error"]));
  const isLoading = useSelector(path(["order", "all", "requesting"]));
  const orders = useSelector(path(["order", "all", "items"]));

  if (isError && isLoaded) {
    return <Redirect to="/" />;
  }

  const Header = (
    <ListOrdersHeader
      searchFunc={searchFunc}
      pageFunc={pageFunc}
      relaunch={relaunch}
      relaunchFunc={relaunchFunc}
    />
  );
  const Footer = (
    <ListOrdersFooter
      page={page}
      pageFunc={pageFunc}
      storeType={storeType}
      storeTypeFunc={storeTypeFunc}
    />
  );

  return (
    <MainLayout header={Header} footer={Footer}>
      {!isLoaded || (isLoading && !isLoaded) ? (
        <LoadingWrapper>
          <Icon
            IconComponent={LoadingIcon}
            stroke="border.default"
            width="100px"
            height="100px"
          />
        </LoadingWrapper>
      ) : (
        <OrdersWrapper>
          {map(
            (order) => (
              <OrderCard
                key={`orderList${order.id}`}
                id={order.id}
                orderNumber={order.orderNumber}
                status={order.status}
                statusHumanize={order.statusHumanize}
                customerId={order.customerId}
                customerName={order.customerName}
                date={moment(order.creationDate).format("L")}
                totalCart={(order.total - order.totalRefund).toFixed(2)}
                products={order.products}
                couponLines={order.couponLines}
                shippingLines={order.shippingLines}
                fullOrder={order}
              />
            ),
            orders
          )}
        </OrdersWrapper>
      )}
    </MainLayout>
  );
};

const ListOrdersContainer = () => {
  const [search, setSearch] = useState("");
  const [storeType, setStoreType] = useState("Online");
  const [page, setPage] = useState(1);
  const [relaunch, setRelaunch] = useState(false);

  useEffect(() => {
    apiService.order.fetchAll({
      actionsCreator: {
        start: () => ({ type: actions.types.order.fetchAll }),
        success: (payload) => ({
          type: actions.types.order.fetchAllSuccess,
          payload,
        }),
        error: () => ({ type: actions.types.order.fetchAllError }),
      },
      urlParameters: {
        store: storeType,
        search,
        page,
      },
      notifications: {
        error: true,
      },
    });
  }, [relaunch, storeType, search, page]);

  return (
    <ListOrders
      searchFunc={setSearch}
      page={page}
      pageFunc={setPage}
      storeType={storeType}
      storeTypeFunc={setStoreType}
      relaunch={relaunch}
      relaunchFunc={setRelaunch}
    />
  );
};

ListOrdersHeader.displayName = "ListOrdersHeader";
ListOrdersHeader.propTypes = {
  searchFunc: PropTypes.func.isRequired,
  pageFunc: PropTypes.func.isRequired,
  relaunch: PropTypes.bool.isRequired,
  relaunchFunc: PropTypes.func.isRequired,
};

ListOrdersFooter.displayName = "ListOrdersFooter";
ListOrdersFooter.propTypes = {
  page: PropTypes.number.isRequired,
  pageFunc: PropTypes.func.isRequired,
  storeType: PropTypes.string.isRequired,
  storeTypeFunc: PropTypes.func.isRequired,
};

ListOrders.displayName = "ListOrders";
ListOrders.propTypes = {
  searchFunc: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  pageFunc: PropTypes.func.isRequired,
  storeType: PropTypes.string.isRequired,
  storeTypeFunc: PropTypes.func.isRequired,
  relaunch: PropTypes.bool.isRequired,
  relaunchFunc: PropTypes.func.isRequired,
};

ListOrdersContainer.displayName = "ListOrdersContainer";

export default ListOrdersContainer;
