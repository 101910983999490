import styled from "@xstyled/styled-components";
import { backgroundColor } from "@xstyled/system";
import { AddCartIcon, EyeIcon } from "#components";

export const OrderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: xxs;
  padding: xs xxs;
  background-color: bg;
  box-shadow: smooth;
  border-radius: default;
`;

export const OrderStatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 100px;
  width: 100px;
  background-color: ko.L1;
  color: text.L9;
  border-radius: default;
  margin: xxs sm;
  padding: xs;
  user-select: none;
  ${backgroundColor}
`;

export const OrderStatus = styled.span`
  font-size: md;
`;

export const OrderId = styled.span`
  padding-top: xxs;
  font-size: sm;
`;

export const OrderInformationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

export const OrderInformation = styled.span`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-bottom: xxs;
`;

export const OrderTitle = styled.span`
  padding-right: xs;
  font-weight: bold;
  font-size: md;
`;

export const OrderText = styled.span`
  font-size: md;
`;

export const OrderActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  min-width: 180px;
  width: 180px;
`;

export const StyledEyeIcon = styled(EyeIcon)`
  height: 30px;
  width: 30px;
`;

export const StyledAddCartIcon = styled(AddCartIcon)`
  height: 30px;
  width: 30px;
`;
