import { createGlobalStyle } from "@xstyled/styled-components";
import { normalize } from "styled-normalize";

const GlobalStyle = createGlobalStyle`
    ${normalize}

    body {
        min-height: 100vh;

        background-color: bg;
        font-family: default;
        font-size: default;
        color: text;
    }

    *, :after, :before {
        box-sizing: border-box;
    }


    h1 {font-size: h1; margin: 0;}
    h2 {font-size: h2;}
    h3 {font-size: h3;}
    h4 {font-size: h4;}
    small, small b {font-size: sm;}
    b,strong {font-weight: bold;}
    h1, h2, h3, h4, { margin: xs 0; line-height: 1;}

    textarea {padding-top: sm;}
`;

export default GlobalStyle;
