import { generateActions } from "reduxt";
import { notifications } from "#constants";

const [authAT, authAC] = generateActions(
  "auth",
  "authenticate",
  "authenticate success",
  "authenticate error",
  "logout"
);

const [cartAT, cartAC] = generateActions(
  "cart",
  "add item",
  "update item",
  "remove item",
  "assign customer",
  "assign order",
  "clear cart",
  "clear customer",
  "fetch settings",
  "fetch settings success",
  "fetch settings error",
  "change status",
  "change shipping method",
  "add coupon",
  "remove coupon"
);

const [couponAT, couponAC] = generateActions(
  "coupon",
  "fetch all",
  "fetch all success",
  "fetch all error"
);

const [customerAT, customerAC] = generateActions(
  "customer",
  "fetch all",
  "fetch all success",
  "fetch all error",
  "fetch orders",
  "fetch orders success",
  "fetch orders error",
  "fetch one",
  "fetch one success",
  "fetch one error"
);

const [notificationsAT, notificationsAC] = generateActions(
  "notifications",
  `new ${notifications.types.info}`,
  `new ${notifications.types.warning}`,
  `new ${notifications.types.success}`,
  `new ${notifications.types.error}`,
  "clear all",
  "clear by id"
);

const [orderAT, orderAC] = generateActions(
  "order",
  "fetch all",
  "fetch all success",
  "fetch all error",
  "fetch one",
  "fetch one success",
  "fetch one error",
  "create",
  "create success",
  "create error",
  "create clear",
  "update order",
  "custom order"
);

const [productAT, productAC] = generateActions(
  "product",
  "fetch all",
  "fetch all success",
  "fetch all error",
  "fetch one",
  "fetch one success",
  "fetch one error"
);

const [storeAT, storeAC] = generateActions(
  "store",
  "fetch one",
  "fetch one success",
  "fetch one error"
);

export default {
  types: {
    auth: authAT,
    cart: cartAT,
    coupon: couponAT,
    customer: customerAT,
    notifications: notificationsAT,
    order: orderAT,
    product: productAT,
    store: storeAT,
  },
  creators: {
    auth: authAC,
    cart: cartAC,
    coupon: couponAC,
    customer: customerAC,
    notifications: notificationsAC,
    order: orderAC,
    product: productAC,
    store: storeAC,
  },
};
