import styled, { css } from "@xstyled/styled-components";
import { variant, width } from "@xstyled/system";
import { createCleanComponent } from "../../../utils";

export const SWrapper = styled.div`
  position: relative;
`;

const CleanInput = createCleanComponent("input", ["width"]);

export const SInput = styled(CleanInput)`
  border-style: solid;
  display: flex;
  resize: none;

  color: text;
  background-color: fg;
  border-width: default;
  border-radius: default;
  font-size: default;
  transition: default;
  width: 100%;

  ${({ as, icon, theme }) =>
    as === "textarea"
      ? css({
          padding: "sm",
        })
      : css({
          padding: icon ? `0 calc(${theme.space.sm} + 24px) 0 sm` : "0 sm",
        })}

  ${({ isInvalid }) =>
    css({
      borderColor: isInvalid ? "ko" : "border",
      color: isInvalid ? "ko" : "text",
    })};

  ${({ isDisabled }) =>
    css({
      opacity: isDisabled ? "0.75" : "1",
      cursor: isDisabled ? "not-allowed" : "initial",
    })};

  &:focus {
    outline: none;

    background-color: fg.L1;
    box-shadow: default;
    ${({ isInvalid }) =>
      css({
        borderColor: isInvalid ? "ko" : "primary",
      })};
  }

  &::placeholder {
    font-family: default;
    color: border;
  }

  ${variant({
    default: "default",
    prop: "size",
    variants: {
      default: css`
        height: form;
        ${({ as }) =>
          css({
            height: as === "textarea" ? "80px" : "form",
          })}
      `,

      sm: css`
        height: formSM;
        font-size: md;
        ${({ as }) =>
          css({
            height: as === "textarea" ? "80px" : "formSM",
          })}
      `,
    },
  })}

  ${width}
`;

export const SIcon = styled.div`
  position: absolute;
  top: 0;
  right: 0;

  ${({ isDisabled }) =>
    css({
      opacity: isDisabled ? "0.75" : "1",
      cursor: isDisabled ? "not-allowed" : "initial",
    })};
  ${({ onClick }) =>
    !!onClick &&
    css`
      &:hover {
        color: primary;
        cursor: pointer;
      }
    `};

  ${variant({
    default: "default",
    prop: "size",
    variants: {
      default: css`
        width: form;
        height: form;
        padding: xs;
      `,
      sm: css`
        width: formSM;
        height: formSM;
        padding: xxs;
      `,
    },
  })}
`;

export const InputGroup = styled.div`
  display: inline-flex;
  align-items: center;

  input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    margin-right: 0;
  }

  & > * {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    ${({ theme }) =>
      css({
        marginLeft: `calc(-1 * ${theme.borderWidths.default})`,
      })}
  }
`;
