import styled, { css } from "@xstyled/styled-components";
import { Checkbox } from "reakit";
import { Check, Remove } from "styled-icons/material";

export const SCheckIcon = styled(Check)`
  fill: white;
`;

export const SIndeterminateIcon = styled(Remove)`
  fill: white;
`;

export const SWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  position: relative;
  width: fit-content;
  margin-right: default;
`;

export const SContent = styled.div`
  width: fit-content;

  height: form;
`;

export const SMask = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  ${({ isDisabled }) =>
    css({
      display: isDisabled ? "block" : "none",
      userSelect: isDisabled ? "none" : "all",
      cursor: isDisabled ? "not-allowed" : "initial",
      opacity: isDisabled ? "0.5" : "1",
    })};
`;

export const SLabel = styled.label`
  display: flex;
  align-items: center;
  height: 100%;
`;

export const SCheckBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-style: solid;
  pointer-events: none;

  border-width: default;
  border-radius: sm;
  margin-right: xs;
  transition: default;
  height: formXS;
  width: formXS;

  ${({ isChecked }) =>
    css({
      backgroundColor: isChecked ? "primary" : "fg",
      borderColor: isChecked ? "primary" : "border",
    })};
  ${({ isHover }) =>
    css({
      boxShadow: isHover ? "default" : "none",
    })};
`;

export const SImage = styled.img`
  background: none;

  ${({ isChecked }) =>
    css({
      opacity: isChecked ? "1" : "0",
    })};
`;

export const SText = styled.span`
  transition: default;
  font-size: md;

  ${({ isChecked }) =>
    css({
      fontWeight: isChecked ? "bold" : "normal",
    })};
`;

export const SRealInput = styled(Checkbox)`
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;

  height: form;
`;
