import styled from "@xstyled/styled-components";

export const UserNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: xxs;
  background-color: bg;
  border-radius: default;
  height: 175px;
`;

export const UserName = styled.span`
  font-size: h3;
`;
