import PropTypes from "prop-types";
import React from "react";
import globalProps from "../../../props";
import { useFormControl } from "../FormControl";
import { SSlider, SWrapper } from "./styled";

/**
 *
 * Input is a basic component that using the basic markup 'range input'.
 *
 * It uses FormContext to connect the form context.
 */
const Slider = ({ value, min, max, className, ...props }) => {
  const { isDisabled, ...updatedProps } = useFormControl(props);
  return (
    <SWrapper className={className}>
      <SSlider
        disabled={isDisabled}
        style={{
          "--min": min,
          "--max": max,
          "--val": value,
        }}
        {...updatedProps}
      />
    </SWrapper>
  );
};

Slider.defaultProps = {
  ...globalProps.styledComponents.className.defaultProps,
  isInvalid: undefined,
  isRequired: undefined,
  isDisabled: undefined,
  isReadOnly: undefined,
  min: 0,
  max: 100,
  step: 1,
  value: undefined,
};

Slider.propTypes = {
  ...globalProps.styledComponents.className.types,
  isInvalid: PropTypes.bool,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  value: PropTypes.number,
};

export default Slider;
