import styled, { Box, css } from "@xstyled/styled-components";
import { up } from "@xstyled/system";
import { strPath } from "ramdu";
import { notifications } from "#constants";

const getColorNotificationByType = ({ type, theme }) =>
  strPath(`colors.${notifications.colors[type]}.D1`, theme);

const getHoverColorNotificationByType = ({ type, theme }) =>
  strPath(`colors.${notifications.colors[type]}.D2`, theme);

export const StyledNotifications = styled(Box)`
  position: absolute;
  top: 10px;
  right: 50px;
  width: 100%;
  z-index: topApp;
  padding: 0 default;

  ${up(
    "md",
    css`
      left: 30px;
      width: 100%;
      max-width: 510px;
    `
  )}
`;

export const StyledNotification = styled.div`
  font-weight: 500;
  line-height: 24px;

  transition: all 0.2s;
  padding: xs default;
  margin-bottom: sm;
  box-shadow: 0 0 10px #00000026;
  background-color: ${getColorNotificationByType};
  color: text.L9;
  text-align: center;
  opacity: 1;

  :hover {
    background-color: ${getHoverColorNotificationByType};
  }
`;
