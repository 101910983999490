import styled from "@xstyled/styled-components";
import { height, width } from "@xstyled/system";
import PropTypes from "prop-types";
import { split } from "ramda";
import React from "react";

const getFill = ({ fill, theme }) => {
  const splitFill = fill && split(".", fill);
  if (!splitFill) return ``;

  const fillColor =
    splitFill && splitFill.length !== 1
      ? theme.colors[splitFill[0]][splitFill[1]]
      : fill;

  return `
    fill: ${fillColor};

    path {
      fill: ${fillColor};
    }

    circle {
      fill: ${fillColor};
    }
  `;
};

const getStroke = ({ stroke, theme }) => {
  const splitStroke = stroke && split(".", stroke);
  if (!splitStroke) return ``;

  const strokeColor =
    splitStroke && splitStroke.length !== 1
      ? theme.colors[splitStroke[0]][splitStroke[1]]
      : stroke;

  return `
    stroke: ${strokeColor};
  `;
};

const SIcon = styled.svg`
  ${width}
  ${height}
  ${getFill}
  ${getStroke}
`;

const Icon = ({ IconComponent, ...props }) => (
  <SIcon as={IconComponent} {...props} />
);

export default Icon;

Icon.displayName = "Icon";

Icon.defaultProps = {
  IconComponent: null,
  width: "24px",
  height: "24px",
};

Icon.propTypes = {
  IconComponent: PropTypes.shape({
    $$typeof: PropTypes.symbol,
    render: PropTypes.func,
  }),
  width: PropTypes.string,
  height: PropTypes.string,
};
