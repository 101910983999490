import styled from "@xstyled/styled-components";
import { width } from "@xstyled/system";
import FormControl from "./FormControl";
import Select from "./Select";

export const FormWrapper = styled.div`
  border-style: solid;
  max-width: 700px;
  margin: auto;

  background-color: fg.A6;
  box-shadow: smooth;
  border-width: default;
  border-radius: sm;
  border-color: border.default;
  padding: sm default;
  border-radius: card;

  &:hover {
    box-shadow: default;
  }
`;

export const FormTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: sm;
  font-size: h3;
  font-weight: bold;
  text-align: center;
  color: secondary.default;
`;

export const FormImage = styled.img`
  with: 50px;
  height: 50px;
`;

export const StyledFormControl = styled(FormControl)`
  ${width};
`;

export const StyledSelect = styled(Select)`
  ${width};
`;
