import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import globalProps from "../../../props";
import {
  FormControlDefaultProps,
  FormControlPropTypes,
  useFormControl,
} from "../FormControl";
import {
  SCheckBox,
  SCheckIcon,
  SContent,
  SIndeterminateIcon,
  SLabel,
  SMask,
  SRealInput,
  SText,
  SWrapper,
} from "./styles";

/**
 * Can be controlled (parent component controls its value)
 * or uncontrolled (it controls its value on its own).
 *
 * If controlled: "checked" prop is required. "onClick" prop is optional for handling.
 * If uncontrolled: "onChange" prop is optional for handling. "initialCheck" is optional for initial check status.
 */
const CheckBox = ({
  label,
  checked,
  onChange,
  onClick,
  initialCheck,
  value,
  isIndeterminate,
  className,
  ...props
}) => {
  // Controlled/uncontrolled component
  const isControlled = checked !== undefined;

  // FormControl flags
  const { isDisabled } = useFormControl(props);

  // Checked state is internally handled only in case of uncontrolled component
  const [isCheckedState, setIsCheckedState] = useState(initialCheck);
  const isChecked = isControlled ? checked : isCheckedState;
  const toggleIsChecked = useCallback(() => {
    if (isDisabled) {
      return;
    }

    if (isControlled) {
      if (onClick) {
        onClick(value);
      }
    } else {
      setIsCheckedState(!isChecked);
      if (onChange) {
        onChange(!isChecked);
      }
    }
  }, [
    isControlled,
    setIsCheckedState,
    isChecked,
    onChange,
    onClick,
    value,
    isDisabled,
  ]);

  // Hover state is always internally handled
  const [isHover, setIsHover] = useState(false);
  const toggleIsHover = useCallback(() => setIsHover((state) => !state), [
    setIsHover,
  ]);

  return (
    <SWrapper className={className}>
      <SContent
        onMouseEnter={toggleIsHover}
        onMouseLeave={toggleIsHover}
        isDisabled={isDisabled}
      >
        <SLabel onClick={toggleIsChecked}>
          <SRealInput type="checkbox" checked={isChecked} />
          <SCheckBox
            data-testid="checkbox"
            isChecked={isChecked || isIndeterminate}
            isHover={isHover}
            isDisabled={isDisabled}
          >
            {isChecked && <SCheckIcon data-testid="checkIcon" size={24} />}
            {isIndeterminate && (
              <SIndeterminateIcon data-testid="checkIcon" size={24} />
            )}
          </SCheckBox>
          <SText isChecked={isChecked}>{label}</SText>
        </SLabel>
      </SContent>
      <SMask isDisabled={isDisabled} />
    </SWrapper>
  );
};

CheckBox.defaultProps = {
  ...FormControlDefaultProps,
  ...globalProps.styledComponents.className.defaultProps,
  label: "",
  initialCheck: false,
  isIndeterminate: false,
  checked: undefined,
  onChange: undefined,
  onClick: undefined,
};

CheckBox.propTypes = {
  ...FormControlPropTypes,
  ...globalProps.styledComponents.className.types,
  /** Text to display next to the checkbox  */
  label: PropTypes.string,

  initialCheck: PropTypes.bool,
  checked: PropTypes.bool,

  /** Generic on change handler */
  onChange: PropTypes.func,

  /** Generic on click handler */
  onClick: PropTypes.func,

  /** Indeterminate state */
  isIndeterminate: PropTypes.bool,
};

export default CheckBox;
