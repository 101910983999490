const space = {
  xxs: "4px",
  xs: "8px",
  sm: "16px",
  default: "30px",
  lg: "50px",
  xl: "64px",
};

export default space;
