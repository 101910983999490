import PropTypes from "prop-types";
import React from "react";
import FormControl from "./FormControl";
import Input from "./Input";
import Label from "./Label";

const FormInput = ({
  name,
  label,
  type,
  value,
  onChange,
  error,
  required,
  ...props
}) => (
  <FormControl isRequired={required} isInvalid={error}>
    <Label name={label} />
    <Input
      name={name}
      value={value}
      type={type}
      autocomplete="off"
      onChange={onChange}
      {...props}
    />
  </FormControl>
);

export default FormInput;

FormInput.displayName = "FormInput";

FormInput.defaultProps = {
  type: "text",
  error: false,
  required: true,
};

FormInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["text", "password", "email"]),
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
  required: PropTypes.bool,
};
