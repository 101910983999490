import PropTypes from "prop-types";
import { equals } from "ramda";
import React from "react";
import { Redirect, Route } from "react-router-dom";

const RedirectToLoggedRoot = () => <Redirect to="/dashboard" />;
const RedirectToUnloggedRoot = () => <Redirect to="/" />;

const GenericRoute = ({ path, hasAccess, FallbackComponent, component }) => {
  if (!hasAccess) return <FallbackComponent />;
  return <Route path={path} component={component} />;
};

GenericRoute.displayName = "GenericRoute";

GenericRoute.defaultProps = {
  path: "",
  hasAccess: false,
  FallbackComponent: RedirectToUnloggedRoot,
  component: React.Fragment,
};

GenericRoute.propTypes = {
  path: PropTypes.string,
  hasAccess: PropTypes.bool,
  FallbackComponent: PropTypes.func,
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

export const LoggedRoute = ({ ...props }) => {
  const isLoggedUser = equals(localStorage.getItem("isLoggedIn"), "true");
  return <GenericRoute {...props} hasAccess={isLoggedUser} />;
};

export const UnloggedRoute = ({ ...props }) => {
  const isUnloggedUser = !localStorage.getItem("isLoggedIn");
  return (
    <GenericRoute
      {...props}
      hasAccess={isUnloggedUser}
      FallbackComponent={RedirectToLoggedRoot}
    />
  );
};

export default GenericRoute;
