import makeService, { makeServiceHooks } from "./makeService";

const service = makeService({
  api: process.env.REACT_APP_API_URL,
  token: true,
});

const { toFetchHook, toPostHook, toUpdateHook } = makeServiceHooks(service);

const auth = {
  authenticate: toPostHook("api/auth"),
};

const coupon = {
  fetchAll: toFetchHook("api/coupons?search={{search}}&page={{page}}"),
};

const customer = {
  fetchAll: toFetchHook("api/customers?search={{search}}&page={{page}}"),
  fetchCustomerOrders: toFetchHook(
    "api/orders?customer={{customerId}}&page={{page}}"
  ),
};

const order = {
  fetchAll: toFetchHook(
    "api/orders?store={{store}}&search={{search}}&page={{page}}"
  ),
  fetchOne: toFetchHook("api/orders/{{orderId}}"),
  create: toPostHook("api/orders"),
  createRefund: toPostHook("api/orders/refund"),
  update: toUpdateHook("api/orders/{{orderId}}"),
};

const product = {
  fetchAll: toFetchHook("api/products?search={{search}}&page={{page}}"),
};

const setting = {
  fetch: toFetchHook("api/stores/settings"),
};

const user = {
  fetchProfile: toFetchHook("api/users/profile"),
};

export default {
  auth,
  coupon,
  customer,
  product,
  order,
  setting,
  user,
};
