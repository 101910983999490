import styled from "@xstyled/styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const LoginWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
`;

export const MessageInfo = styled.p`
  text-align: center;
  font-weight: bold;
`;

export const NotificationWrapper = styled.div`
  position: relative;
  width: 50%;
  margin: 0 auto;
`;
