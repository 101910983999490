import { combineReducers } from "redux";
import auth from "./auth";
import cart from "./cart";
import coupon from "./coupon";
import customer from "./customer";
import notifications from "./notifications";
import order from "./order";
import product from "./product";
import store from "./store";

const rootReducer = combineReducers({
  auth,
  cart,
  coupon,
  customer,
  notifications,
  order,
  product,
  store,
});

export default rootReducer;
