import { both, either, is, isEmpty, isNil, not, pipe } from "ramda";

export const isNotEmpty = pipe(isEmpty, not);

export const isNotNil = pipe(isNil, not);

export const isTruthy = both(isNotEmpty, isNotNil);

export const isNotTruthy = either(isEmpty, isNil);

export const isString = is(String);

export const isNotString = pipe(is(String), not);

export const isObject = (input) => typeof input === "object";
export const isArray = (input) => Array.isArray(input);

export const omit = (propsToRemove = [], obj = {}) =>
  Object.keys(obj).reduce((acc, key) => {
    if (!propsToRemove.includes(key)) {
      acc[key] = obj[key];
    }
    return acc;
  }, {});
