import { filter, prepend } from "ramda";
import { generateReducer } from "reduxt";
import { v4 as randomId } from "uuid";
import { notifications } from "#constants";
import actions from "../actions";

const initialState = [];

const getNotification = (type, message = "") => ({
  type,
  message,
  id: randomId(),
});

const newInfoFn = (state, { payload }) =>
  prepend(getNotification(notifications.types.info, payload), state);

const newWarningFn = (state, { payload }) =>
  prepend(getNotification(notifications.types.warning, payload), state);

const newSuccessFn = (state, { payload }) =>
  prepend(getNotification(notifications.types.success, payload), state);

const newErrorFn = (state, { payload }) =>
  prepend(getNotification(notifications.types.error, payload), state);

const clearByIdFn = (state, { payload }) =>
  filter(({ id }) => id !== payload, state);
const clearAllFn = () => initialState;

const reducer = generateReducer(initialState, {
  [actions.types.notifications.newInfo]: newInfoFn,
  [actions.types.notifications.newWarning]: newWarningFn,
  [actions.types.notifications.newSuccess]: newSuccessFn,
  [actions.types.notifications.newError]: newErrorFn,
  [actions.types.notifications.clearById]: clearByIdFn,
  [actions.types.notifications.clearAll]: clearAllFn,
});

export default reducer;
