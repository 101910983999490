import React from "react";
import { ArrowBackIcon, MainLayout } from "#components";
import { EditCustomerHeader } from "../EditCustomerCommon";

const CustomerInfo = () => {
  return (
    <MainLayout
      headerIcon={ArrowBackIcon}
      headerIconLink="/customers"
      header={<EditCustomerHeader />}
    >
      <p>Customer info</p>
    </MainLayout>
  );
};

export default CustomerInfo;
