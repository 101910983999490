import PropTypes from "prop-types";
import React from "react";
import globalProps from "../../../props";
import { SDropdownButton } from "../../Dropdown";

const Select = ({ options, className, ...props }) => (
  <SDropdownButton as="select" className={className} {...props}>
    {options.map((option) =>
      typeof option === "string" ? (
        <option key={option}>{option}</option>
      ) : (
        <option key={`${option.title}-${option.value}`} value={option.value}>
          {option.title}
        </option>
      )
    )}
  </SDropdownButton>
);

Select.defaultProps = {
  ...globalProps.styledComponents.className.defaultProps,
};

Select.propTypes = {
  ...globalProps.styledComponents.className.types,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        title: PropTypes.string,
      }),
    ])
  ).isRequired,
};

export default Select;
