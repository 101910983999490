import PropTypes from "prop-types";

export const FormControlDefaultProps = Object.freeze({
  isInvalid: undefined,
  isRequired: undefined,
  isDisabled: undefined,
  isReadOnly: undefined,
});

export const FormControlPropTypes = Object.freeze({
  isInvalid: PropTypes.bool,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isReadOnly: PropTypes.bool,
});
