import PropTypes from "prop-types";
import { path } from "ramda";
import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { actions } from "#store";
import { Button, PrintIcon, PrintReceipt, ReceiptLongIcon } from "#components";
import {
  OrderActions,
  OrderId,
  OrderInformation,
  OrderInformationWrapper,
  OrderStatus,
  OrderStatusWrapper,
  OrderText,
  OrderTitle,
  OrderWrapper,
  StyledAddCartIcon,
  StyledEyeIcon,
} from "./styles";

const getStatusColor = (status) => {
  switch (status) {
    case "failed":
      return "ko.D1";
    case "on-hold":
      return "warning.D1";
    case "processing":
      return "ok.D1";
    case "completed":
      return "primary.D1";
    case "refunded":
    case "pending":
    case "cancelled":
      return "secondary.D1";
    default:
      return null;
  }
};

const OrderCard = ({
  id,
  orderNumber,
  status,
  statusHumanize,
  store,
  customerId,
  customerName,
  date,
  totalCart,
  products,
  couponLines,
  shippingLines,
  fullOrder,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const componentRef = useRef();

  const cartCustomerName = useSelector(path(["cart", "customer", "name"]));
  const cartProducts = useSelector(path(["cart", "products"]));

  const handlerAddOrderToCurrentCart = () => {
    dispatch(
      actions.creators.cart.assignOrder({
        customer: {
          id: customerId,
          name: customerName,
        },
        products,
        order: {
          id,
          orderNumber,
          status,
          statusHumanize,
          coupons: couponLines,
          shippingMethod:
            shippingLines && shippingLines.length > 0 && shippingLines[0],
        },
      })
    );
  };

  return (
    <OrderWrapper>
      {status && orderNumber && (
        <OrderStatusWrapper backgroundColor={getStatusColor(status)}>
          <OrderStatus>{statusHumanize || status}</OrderStatus>
          <OrderId>#{orderNumber}</OrderId>
        </OrderStatusWrapper>
      )}
      {(store || customerName || date || totalCart) && (
        <OrderInformationWrapper>
          {!store && customerName && (
            <OrderInformation>
              <OrderTitle>Cliente:</OrderTitle>
              <OrderText>{customerName}</OrderText>
            </OrderInformation>
          )}
          {date && (
            <OrderInformation>
              <OrderTitle>Fecha:</OrderTitle>
              <OrderText>{date}</OrderText>
            </OrderInformation>
          )}
          {store && (
            <OrderInformation>
              <OrderTitle>Venta:</OrderTitle>
              <OrderText>{store}</OrderText>
            </OrderInformation>
          )}
          {totalCart && (
            <OrderInformation>
              <OrderTitle>Total carrito:</OrderTitle>
              <OrderText>{totalCart} €</OrderText>
            </OrderInformation>
          )}
        </OrderInformationWrapper>
      )}
      {id && (
        <OrderActions>
          <Button
            variant="link"
            size="sm"
            icon={StyledEyeIcon}
            onClick={() => history.push(`/order/${id}`)}
          />
          <PrintReceipt
            variant="link"
            size="sm"
            shape="square"
            icon={PrintIcon}
            isListOrder
            order={fullOrder}
            componentRef={componentRef}
          />
          <Button
            variant="link"
            size="sm"
            icon={ReceiptLongIcon}
            onClick={() => history.push(`/order/${id}/custom-receipt`)}
          />
          {["pending", "on-hold", "processing"].includes(status) &&
            !cartCustomerName &&
            cartProducts.length === 0 && (
              <Button
                variant="link"
                size="sm"
                icon={StyledAddCartIcon}
                onClick={handlerAddOrderToCurrentCart}
              />
            )}
        </OrderActions>
      )}
    </OrderWrapper>
  );
};

OrderCard.displayName = "OrderCard";

OrderCard.defaultProps = {
  store: undefined,
  statusHumanize: undefined,
};

OrderCard.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  orderNumber: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  status: PropTypes.string.isRequired,
  statusHumanize: PropTypes.string,
  store: PropTypes.oneOf(["TPV", "Online", "Other"]),
  customerId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  customerName: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  totalCart: PropTypes.string.isRequired,
  products: PropTypes.arrayOf(PropTypes.shape).isRequired,
  couponLines: PropTypes.arrayOf(PropTypes.shape).isRequired,
  shippingLines: PropTypes.arrayOf(PropTypes.shape).isRequired,
  fullOrder: PropTypes.shape.isRequired,
};

export default OrderCard;
