import tinycolor from "tinycolor2";

const storageColors = {};

export const generateColor = (color) => {
  if (storageColors[color]) {
    return storageColors[color];
  }

  const enhancedColor = {
    default: tinycolor(color).toHex8String(),
    A9: tinycolor(color).setAlpha(0.9).toHex8String(),
    A8: tinycolor(color).setAlpha(0.8).toHex8String(),
    A7: tinycolor(color).setAlpha(0.7).toHex8String(),
    A6: tinycolor(color).setAlpha(0.6).toHex8String(),
    A5: tinycolor(color).setAlpha(0.5).toHex8String(),
    A4: tinycolor(color).setAlpha(0.4).toHex8String(),
    A3: tinycolor(color).setAlpha(0.3).toHex8String(),
    A2: tinycolor(color).setAlpha(0.2).toHex8String(),
    A1: tinycolor(color).setAlpha(0.1).toHex8String(),
    D9: tinycolor(color).darken(90).toString(),
    D8: tinycolor(color).darken(80).toString(),
    D7: tinycolor(color).darken(70).toString(),
    D6: tinycolor(color).darken(60).toString(),
    D5: tinycolor(color).darken(50).toString(),
    D4: tinycolor(color).darken(40).toString(),
    D3: tinycolor(color).darken(30).toString(),
    D2: tinycolor(color).darken(20).toString(),
    D1: tinycolor(color).darken(10).toString(),
    L9: tinycolor(color).lighten(90).toHex8String(),
    L8: tinycolor(color).lighten(80).toHex8String(),
    L7: tinycolor(color).lighten(70).toHex8String(),
    L6: tinycolor(color).lighten(60).toHex8String(),
    L5: tinycolor(color).lighten(50).toHex8String(),
    L4: tinycolor(color).lighten(40).toHex8String(),
    L3: tinycolor(color).lighten(30).toHex8String(),
    L2: tinycolor(color).lighten(20).toHex8String(),
    L1: tinycolor(color).brighten(10).toHex8String(),
  };
  storageColors[color] = enhancedColor;
  return enhancedColor;
};

const generatePalette = (colors) =>
  Object.keys(colors).reduce((acc, currKey) => {
    const color = generateColor(colors[currKey]);
    return { ...acc, [currKey]: color };
  }, {});

export default generatePalette;
