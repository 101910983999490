import styled from "@xstyled/styled-components";
import { display } from "@xstyled/system";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;
`;

export const SidebarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 100px;
  width: 100px;
  height: 100%;
  ${display};
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 200px;
  width: 100%;
  height: 100%;
  background-color: fg.D1;
`;

export const OrderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 350px;
  width: 350px;
  height: 100%;
  border-left-style: solid;
  border-left-width: bold;
  border-left-color: border.D3;
`;

export const SiteTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  min-height: 50px;
  height: 50px;
  background-color: primary.D1;
  color: bg.L9;
  border-right-style: solid;
  border-right-width: bold;
  border-right-color: border.D3;
`;
