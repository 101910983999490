const fontSizes = {
  h1: "40px",
  h2: "32px",
  h3: "22px",
  h4: "15px",
  sm: "12px",
  md: "14px",
  default: "16px",
};

export default fontSizes;
