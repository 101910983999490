import moment from "moment";
import PropTypes from "prop-types";
import { find, map, path, propEq, toUpper } from "ramda";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { actions } from "#store";
import { apiService } from "#services";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  Button,
  Icon,
  LoadingIcon,
  MainLayout,
  RefreshIcon,
  Searcher,
} from "#components";
import {
  CouponActions,
  CouponCode,
  CouponExpired,
  CouponInformationWrapper,
  CouponsWrapper,
  CouponWrapper,
  LoadingWrapper,
  StyledAddCartIcon,
} from "./styles";

const ListCouponsHeader = ({
  searchFunc,
  pageFunc,
  relaunch,
  relaunchFunc,
}) => {
  const [coupon, setCoupon] = useState("");

  return (
    <>
      <Searcher
        name="couponSearcher"
        placeholder="Ingrese el cupón a buscar"
        size="sm"
        value={coupon}
        onChange={setCoupon}
        onClick={() => {
          pageFunc(1);
          searchFunc(coupon);
        }}
      />
      <Button
        variant="link"
        size="sm"
        shape="square"
        icon={RefreshIcon}
        iconColor="text"
        onClick={() => relaunchFunc(!relaunch)}
      />
    </>
  );
};

const ListCouponsFooter = ({ page, pageFunc }) => {
  return (
    <>
      <div />
      <div>
        {page !== 1 && (
          <Button
            variant="link"
            size="sm"
            shape="square"
            icon={ArrowLeftIcon}
            onClick={() => pageFunc(page - 1)}
          />
        )}
        <Button
          variant="link"
          size="sm"
          shape="square"
          icon={ArrowRightIcon}
          onClick={() => pageFunc(page + 1)}
        />
      </div>
    </>
  );
};

const ListCoupons = ({
  searchFunc,
  page,
  pageFunc,
  relaunch,
  relaunchFunc,
}) => {
  const dispatch = useDispatch();
  const isLoaded = useSelector(path(["coupon", "all", "loaded"]));
  const isError = useSelector(path(["coupon", "all", "error"]));
  const isLoading = useSelector(path(["coupon", "all", "requesting"]));
  const coupons = useSelector(path(["coupon", "all", "items"]));

  const cartCoupons = useSelector(path(["cart", "coupons"]));

  if (isError && isLoaded) {
    return <Redirect to="/" />;
  }

  const handlerAddCouponCart = (coupon) => {
    dispatch(actions.creators.cart.addCoupon(coupon));
  };

  const Header = (
    <ListCouponsHeader
      searchFunc={searchFunc}
      pageFunc={pageFunc}
      relaunch={relaunch}
      relaunchFunc={relaunchFunc}
    />
  );
  const Footer = <ListCouponsFooter page={page} pageFunc={pageFunc} />;

  return (
    <MainLayout header={Header} footer={Footer}>
      {!isLoaded || (isLoading && !isLoaded) ? (
        <LoadingWrapper>
          <Icon
            IconComponent={LoadingIcon}
            stroke="border.default"
            width="100px"
            height="100px"
          />
        </LoadingWrapper>
      ) : (
        <CouponsWrapper>
          {map(({ id, code, dateExpires }) => {
            const isExpired =
              dateExpires &&
              moment(dateExpires).isSameOrBefore(new Date(), "day");

            return (
              code && (
                <CouponWrapper key={`couponList${id}`}>
                  <CouponInformationWrapper>
                    {isExpired && (
                      <CouponExpired>{toUpper("Expirado")}</CouponExpired>
                    )}
                    <CouponCode>{toUpper(code)}</CouponCode>
                  </CouponInformationWrapper>

                  <CouponActions>
                    {!isExpired && !find(propEq("code", code), cartCoupons) && (
                      <Button
                        variant="link"
                        size="sm"
                        icon={StyledAddCartIcon}
                        onClick={() => handlerAddCouponCart({ id, code })}
                      />
                    )}
                  </CouponActions>
                </CouponWrapper>
              )
            );
          }, coupons)}
        </CouponsWrapper>
      )}
    </MainLayout>
  );
};

const ListCouponsContainer = () => {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [relaunch, setRelaunch] = useState(false);

  useEffect(() => {
    apiService.coupon.fetchAll({
      actionsCreator: {
        start: () => ({ type: actions.types.coupon.fetchAll }),
        success: (payload) => ({
          type: actions.types.coupon.fetchAllSuccess,
          payload,
        }),
        error: () => ({ type: actions.types.coupon.fetchAllError }),
      },
      urlParameters: {
        search,
        page,
      },
      notifications: {
        error: true,
      },
    });
  }, [relaunch, search, page]);

  return (
    <ListCoupons
      searchFunc={setSearch}
      page={page}
      pageFunc={setPage}
      relaunch={relaunch}
      relaunchFunc={setRelaunch}
    />
  );
};

ListCouponsHeader.displayName = "ListCouponsHeader";
ListCouponsHeader.propTypes = {
  searchFunc: PropTypes.func.isRequired,
  pageFunc: PropTypes.func.isRequired,
  relaunch: PropTypes.bool.isRequired,
  relaunchFunc: PropTypes.func.isRequired,
};

ListCouponsFooter.displayName = "ListCouponsFooter";
ListCouponsFooter.propTypes = {
  page: PropTypes.number.isRequired,
  pageFunc: PropTypes.func.isRequired,
};

ListCoupons.displayName = "ListCoupons";
ListCoupons.propTypes = {
  searchFunc: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  pageFunc: PropTypes.func.isRequired,
  relaunch: PropTypes.bool.isRequired,
  relaunchFunc: PropTypes.func.isRequired,
};

ListCouponsContainer.displayName = "ListCouponsContainer";

export default ListCouponsContainer;
