import React from "react";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { actions } from "#store";

const Logout = () => {
  const dispatch = useDispatch();

  dispatch(actions.creators.auth.logout());

  return <Redirect to="/" />;
};

Logout.displayName = "Logout";

export default Logout;
