import PropTypes from "prop-types";
import React from "react";
import {
  Button,
  ButtonGroup,
  Input,
  LoadingIcon,
  SearchIcon,
} from "#components";
import { StyledInputGroup, Wrapper } from "./styles";

const Searcher = ({
  name,
  placeholder,
  size,
  value,
  onChange,
  onClick,
  isLoading,
}) => {
  const IconComponent = isLoading ? LoadingIcon : SearchIcon;

  return (
    <Wrapper>
      <StyledInputGroup>
        <Input
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={(event) => onChange(event.target.value)}
          size={size}
        />
      </StyledInputGroup>
      <ButtonGroup>
        <Button
          variant="primary"
          size={size}
          shape="square"
          width="default"
          icon={IconComponent}
          onClick={() => onClick(value)}
        />
      </ButtonGroup>
    </Wrapper>
  );
};

Searcher.displayName = "Searcher";

Searcher.defaultProps = {
  placeholder: undefined,
  size: undefined,
  isLoading: false,
};

Searcher.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  size: PropTypes.oneOf(["default", "sm", "lg", "xl"]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

export default Searcher;
