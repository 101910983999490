import { isArray, isObject } from "./misc";

/**
 * Deep merge on the left and right sources
 * @param {boolean} isLeft - Indicates if left is source
 * @param {object} input1
 * @param {object} input2
 */
const mergeDeep = (isLeft) => (input1 = {}, input2 = {}) => {
  const [obj1, obj2] = isLeft ? [input1, input2] : [input2, input1];
  return Object.keys(obj1).reduce(
    (acc, currKey) => {
      const element = obj1[currKey];
      if (isObject(element) && !isArray(element)) {
        acc[currKey] = mergeDeep(true)(element, acc[currKey]);
      } else {
        acc[currKey] = element;
      }
      return acc;
    },
    { ...obj2 }
  );
};

export const mergeDeepLeft = mergeDeep(true);
export const mergeDeepRight = mergeDeep(false);
