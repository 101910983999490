import styled from "@xstyled/styled-components";

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 50px;
  height: 50px;
  background-color: primary.D1;
  color: bg.L9;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  padding: xs;
`;

export const SiteTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100px;
  width: 100px;
  height: 100%;
  font-weight: bold;
  background-color: primary.D1;
  color: bg.L9;
  border-right-style: solid;
  border-right-width: bold;
  border-right-color: border.D3;
`;
