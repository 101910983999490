import PropTypes from "prop-types";
import React from "react";
import globalProps from "../../../props";
import { useFormControl } from "../FormControl";
import { SWrapper } from "./styles";

const ErrorMessage = ({ text, className, ...props }) => {
  const updatedProps = useFormControl(props);

  return (
    <SWrapper className={className} {...updatedProps}>
      {text}
    </SWrapper>
  );
};

ErrorMessage.defaultProps = {
  ...globalProps.styledComponents.className.defaultProps,
  isInvalid: undefined,
  isRequired: undefined,
  isDisabled: undefined,
  isReadOnly: undefined,
};

ErrorMessage.propTypes = {
  ...globalProps.styledComponents.className.types,
  isInvalid: PropTypes.bool,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  text: PropTypes.string.isRequired,
};

export default ErrorMessage;
