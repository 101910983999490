import theme, { modes } from "../..";
import { mergeDeepLeft } from "../../../utils";
import generatePalette from "./generatePalette";

/**
 * Composing available styles modes that include the base and custom modes,
 * where the custom modes overwrite base modes
 * @param {object} customModes - Custom modes provides from external app
 * @param {string[]} activeModes - Activated modes
 */
export const processModes = (customModes = {}, activeModes = []) => {
  const availablesModes = Object.keys({ ...modes, ...customModes });

  const mixedModes = activeModes.reduce((acc, currMode) => {
    const mode = modes[currMode] || {};
    const customMode = customModes[currMode] || {};
    const enhancedMode = mergeDeepLeft(customMode, mode);
    return mergeDeepLeft(enhancedMode, acc);
  }, {});

  return [mixedModes, availablesModes];
};

/**
 * Composing a mixed theme between base and custom theme, where the custom
 * theme overwrite the base theme and apply the same way to mix the mixed
 * theme with availables modes
 * @param {object} customTheme - Custom theme provides from external app
 * @param {object} mixedModes - Styles of the activated modes
 */
const processTheme = (customTheme, mixedModes) => {
  const enhanceTheme = mergeDeepLeft(customTheme, theme);
  const mixedTheme = mergeDeepLeft(mixedModes, enhanceTheme);
  return {
    ...mixedTheme,
    colors: generatePalette(mixedTheme.colors),
  };
};

export default processTheme;
