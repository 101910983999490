import styled from "@xstyled/styled-components";
import { Link } from "react-router-dom";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-top-style: solid;
  border-top-width: bold;
  border-top-color: border.D3;
  overflow: hidden;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  background-color: bg.default;
  border-bottom-style: solid;
  border-bottom-width: bold;
  border-bottom-color: border.D3;
`;

export const HeaderContentWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-right: xs;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: xxs;
  height: 100%;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: ko.D1;
  padding: 0 xs;
`;

export const StyledIcon = styled.svg`
  height: 25px;
  width: 25px;
`;

export const FooterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 71px;
  height: 71px;
  width: 100%;
  padding: sm;
  border-top-style: solid;
  border-top-width: bold;
  border-top-color: border.D3;
  background-color: bg.default;
`;
