import styled from "@xstyled/styled-components";
import { NavLink } from "react-router-dom";

export const ProductsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: space-evenly;
  width: 100%;
  height: calc(100vh - 173px);
  overflow: auto;
`;

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 173px);
  background-color: bg.L9;
  border-radius: card;
`;

export const ProductWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 175px;
  height: 175px;
  margin: xxs;
  background-color: bg;
  box-shadow: smooth;
  border-radius: default;

  &:hover {
    box-shadow: default;
  }
`;

export const ProductImageWrapper = styled.div`
  width: 100%;
  min-height: 105px;
  height: 105px;
  background-color: bg.D4;
`;

export const ProductImage = styled.img`
  width: 100%;
  height: 100%;
`;

export const ProductInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: xs;
  height: 100%;
  width: 100%;
`;

export const ProductName = styled.span``;

export const ProductPrice = styled.span`
  padding-top: xxs;
  font-weight: bold;
`;

export const ProductStock = styled.span`
  position: absolute;
  background-color: #e67e22;
  padding: xxs;
  font-size: sm;
  font-weight: bold;
  color: text.L9;
  top: 7px;
  left: 7px;
  user-select: none;
`;

export const StyledNavLink = styled(NavLink)`
  color: primary.D3;
  text-decoration: none;
  padding: xs;
  border-style: solid;
  border-width: bold;
  border-color: primary.D2;
  color: primary.D2;
  margin: xxs;

  &.active {
    border-color: primary.L1;
    color: primary.L1;
  }
`;

export const PaginationWrapper = styled.span`
  font-weight: bold;
`;
