const dark = {
  colors: {
    bg: "#21121D",
    text: "#FFF",
    fg: "#1d1d1d",
    border: "#4f3249",
    hover: "#292929",
  },
};

const compact = {
  fontSizes: {
    h1: "30px",
    h2: "24px",
    h3: "17px",
    h4: "11px",
    small: "9px",
    default: "11px",
  },
  space: {
    xxs: "2px",
    xs: "4px",
    sm: "8px",
    default: "15px",
    lg: "25px",
    xl: "32px",
  },
  sizes: {
    form: "30px",
    formXS: "14px",
  },
  radii: {
    sm: "2px",
    default: "3px",
  },
  shadows: {
    default: "0px 2px 8px rgba(0, 0, 0, 0.27);",
  },
};

export default {
  dark,
  compact,
};
