import moment from "moment";
import PropTypes from "prop-types";
import { map, path } from "ramda";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect, useParams } from "react-router-dom";
import { actions } from "#store";
import { apiService } from "#services";
import {
  ArrowBackIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  Button,
  Icon,
  LoadingIcon,
  MainLayout,
  OrderCard,
} from "#components";
import { EditCustomerHeader } from "../EditCustomerCommon";
import { CustomerOrdersWrapper, LoadingWrapper } from "./styles";

const CustomerOrdersFooter = ({ page, pageFunc }) => {
  return (
    <>
      <div />
      <div>
        {page !== 1 && (
          <Button
            variant="link"
            size="sm"
            shape="square"
            icon={ArrowLeftIcon}
            onClick={() => pageFunc(page - 1)}
          />
        )}
        <Button
          variant="link"
          size="sm"
          shape="square"
          icon={ArrowRightIcon}
          onClick={() => pageFunc(page + 1)}
        />
      </div>
    </>
  );
};

const CustomerOrders = ({ page, pageFunc }) => {
  const isLoaded = useSelector(path(["customer", "orders", "loaded"]));
  const isError = useSelector(path(["customer", "orders", "error"]));
  const isLoading = useSelector(path(["customer", "orders", "requesting"]));
  const orders = useSelector(path(["customer", "orders", "items"]));

  if (isError && isLoaded) {
    return <Redirect to="/" />;
  }

  return (
    <MainLayout
      headerIcon={ArrowBackIcon}
      headerIconLink="/customers"
      header={<EditCustomerHeader />}
      footer={<CustomerOrdersFooter page={page} pageFunc={pageFunc} />}
    >
      {!isLoaded || (isLoading && !isLoaded) ? (
        <LoadingWrapper>
          <Icon
            IconComponent={LoadingIcon}
            stroke="border.default"
            width="100px"
            height="100px"
          />
        </LoadingWrapper>
      ) : (
        <CustomerOrdersWrapper>
          {map(
            (order) => (
              <OrderCard
                key={`orderList${order.id}`}
                id={order.id}
                orderNumber={order.orderNumber}
                status={order.status}
                statusHumanize={order.statusHumanize}
                store={order.store}
                customerId={order.customerId}
                customerName={order.customerName}
                date={moment(order.creationDate).format("L")}
                totalCart={(order.total - order.totalRefund).toFixed(2)}
                products={order.products}
                couponLines={order.couponLines}
                shippingLines={order.shippingLines}
              />
            ),
            orders
          )}
        </CustomerOrdersWrapper>
      )}
    </MainLayout>
  );
};

const CustomerOrdersContainer = () => {
  const { customerId } = useParams();

  const [page, setPage] = useState(1);

  useEffect(() => {
    apiService.customer.fetchCustomerOrders({
      actionsCreator: {
        start: () => ({ type: actions.types.customer.fetchOrders }),
        success: (payload) => ({
          type: actions.types.customer.fetchOrdersSuccess,
          payload,
        }),
        error: () => ({ type: actions.types.customer.fetchOrdersError }),
      },
      urlParameters: {
        customerId,
        page,
      },
      notifications: {
        error: true,
      },
    });
  }, [customerId, page]);

  return <CustomerOrders page={page} pageFunc={setPage} />;
};

CustomerOrdersFooter.displayName = "CustomerOrdersFooter";
CustomerOrdersFooter.propTypes = {
  page: PropTypes.number.isRequired,
  pageFunc: PropTypes.func.isRequired,
};

CustomerOrders.displayName = "CustomerOrders";
CustomerOrders.propTypes = {
  page: PropTypes.number.isRequired,
  pageFunc: PropTypes.func.isRequired,
};

CustomerOrdersContainer.displayName = "CustomerOrdersContainer";

export default CustomerOrdersContainer;
