import borderWidths from "./borderWidths";
import colors from "./colors";
import fonts from "./fonts";
import fontSizes from "./fontSizes";
import radii from "./radii";
import shadows from "./shadows";
import sizes from "./sizes";
import space from "./space";
import transformers from "./transformers";
import transitions from "./transitions";
import zIndices from "./zIndices";

export default {
  transformers,
  borderWidths,
  colors,
  fonts,
  fontSizes,
  radii,
  space,
  shadows,
  sizes,
  transitions,
  zIndices,
};
