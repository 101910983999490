import React from "react";
import { useParams } from "react-router-dom";
import { CustomerName, HeaderWrapper, StyledNavLink } from "./styles";

export const EditCustomerHeader = () => {
  const { customerId } = useParams();

  return (
    <HeaderWrapper>
      <CustomerName> </CustomerName>
      <div>
        <StyledNavLink to={`/customer/${customerId}/info`}>
          Información
        </StyledNavLink>
        <StyledNavLink to={`/customer/${customerId}/orders`}>
          Histórico de pedidos
        </StyledNavLink>
      </div>
    </HeaderWrapper>
  );
};

EditCustomerHeader.displayName = "EditCustomerHeader";
