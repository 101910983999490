import styled, { css } from "@xstyled/styled-components";

export const SWrapper = styled.div`
  font-weight: bold;
  margin: xxs 0;

  ${({ isInvalid }) =>
    css({
      color: isInvalid ? "ko" : "text",
    })};

  ${({ isDisabled }) =>
    css({
      opacity: isDisabled ? "0.5" : "1",
    })};
`;
