import React, { Suspense } from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "#theme";
import Pages from "./pages";
import store from "./store";

const App = (
  <Suspense fallback={null}>
    <ThemeProvider>
      <Provider store={store}>
        <BrowserRouter>
          <Pages />
        </BrowserRouter>
      </Provider>
    </ThemeProvider>
  </Suspense>
);

const rootElement = document.querySelector("div#app-root");
render(App, rootElement);
